import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../../layout";

import { getContext } from "../../../lib/signin";
import axiosCall from "../../../lib/axios";
import config from "../../../config";

const PathwayStream = ({ language, signout, os, pageRefs, pageFunctions }) => {
  //////////// INITIALIZATION ///////////
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);
  const readOnly = auth.account_type === "free";

  console.log(auth);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const [gotPathways, setGotPathways] = useState(false);
  const [pathways, setPathways] = useState([]);
  const [pid, setPid] = useState("");
  const [pathway, setPathway] = useState(null);
  const [pathwayDescription, setPathwayDescription] = useState(null);

  const [pathwayBites, setPathwayBites] = useState({});
  const [bites, setBites] = useState([]);
  const [selectedBites, setSelectedBites] = useState([]);

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      if (!gotPathways) getPathways();
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (auth.role === "role_member") {
      navigate("/livestreams");
    } else if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  useEffect(() => {
    //reset pathway bites when pid changes
    if (pid) {
      console.log(pid);
      console.log(pathwayBites);
      if (pathwayBites[pid]) {
        setBites(pathwayBites[pid]);
      } else {
        getPathwayBites();
      }
    }
  }, [pid]);

  const getPathways = async () => {
    let result = await axiosCall("community/pathways", {
      bite_count: true,
      live_check: true,
    });
    if (result.success) {
      setGotPathways(true);
      console.log(result.data);
      setPathways(result.data.pathways);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getPathways();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// BITE SELECTION ///////////
  const pickPathway = (str) => {
    setBites([]);
    setSelectedBites([]);
    let obj = JSON.parse(str);
    setPid(obj._id);
    setPathway(obj.pathway);
    if (obj.description) {
      setPathwayDescription(obj.description);
    } else {
      setPathwayDescription(null);
    }
  };

  const getPathwayBites = async () => {
    let result = await axiosCall("pathway/bites", { pid });
    console.log(result);
    if (result.success) {
      let _pathwayBites = { ...pathwayBites };
      _pathwayBites[pid] = result.data;
      setPathwayBites(_pathwayBites);
      setBites(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getPathwayBites();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const toggleBite = (e) => {
    e.stopPropagation();
    let targetNode;
    let _bid;
    if (e.target.getAttribute("bid")) {
      _bid = e.target.getAttribute("bid");
      targetNode = e.target;
    } else {
      let g1 = e.target.parentNode;
      if (g1.getAttribute("bid")) {
        _bid = g1.getAttribute("bid");
        targetNode = g1;
      } else {
        if (g1.parentNode.getAttribute("bid")) {
          _bid = g1.parentNode.getAttribute("bid");
          targetNode = g1.parentNode;
        }
      }
    }
    if (targetNode.classList.contains("background-contrast")) {
      pickBite(_bid);
    } else {
      pickBite(_bid, true);
    }
  };

  const pickBite = (bid, add = false) => {
    const index = selectedBites.indexOf(bid);
    let selection = [...selectedBites];

    //add to selection
    if (add && index < 0) selection.push(bid);

    //remove from selection
    if (!add && index > -1) selection.splice(index, 1);

    setSelectedBites(selection);
  };

  //////////// START STREAM ///////////

  const startStream = () => {
    let state = { pathway, pid, bites, selectedBites };
    navigate("/pubstream", { state });
  };

  //////////// RENDER GUI ///////////
  const mapped_pathways = pathways.map((pathway, index) => {
    return (
      <option
        value={JSON.stringify(pathway)}
        key={index}
        selected={pathway._id === pid}
      >
        {pathway.pathway}
      </option>
    );
  });

  const mapped_bites = bites.map((bite, index) => {
    return (
      <div
        key={index}
        bid={bite.bid}
        className={
          selectedBites.includes(bite.bid)
            ? "hover background-contrast"
            : "hover"
        }
        style={{
          display: "flex",
          marginTop: "0.5em",
          gap: "0.5em",
          padding: "0.5em",
          borderRadius: "6px",
        }}
        onClick={(e) => toggleBite(e)}
      >
        <div
          className="thumb-block border small"
          style={{
            backgroundImage: `url("${
              config.server.storage.bucket + bite.thumb
            }")`,
          }}
        ></div>
        <div className="block-info">
          <div className="clamped one" style={{ fontWeight: 500 }}>
            {bite.title}
          </div>
          <div
            className="clamped two"
            style={{ fontSize: "0.9em", marginTop: "0.2em" }}
          >
            {bite.about}
          </div>
        </div>
      </div>
    );
  });

  if (community.free_streams) {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          <div className="page-section" style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={language.labels.aria.logo.replace(
                  /{org}/g,
                  community.title
                )}
                className="menu-crest"
                style={{
                  height: "3em",
                  width: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + community.crest
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                  {language.labels.live.new}
                </h1>
                <div className="handle2" style={{ width: "100%" }}>
                  {community.title}
                </div>
              </div>
            </div>
          </div>

          {os !== "desktop" || readOnly ? (
            <div className="page-section">
              <div className="quote-text" style={{ marginTop: "0.5em" }}>
                {readOnly
                  ? language.labels.live.subscription_only
                  : language.labels.live.desktop_only}
              </div>
            </div>
          ) : (
            <>
              {/* pathway selector */}
              {pathways.length > 0 ? (
                <div
                  className="page-section"
                  style={{ marginTop: "0.5em", maxWidth: "66rem" }}
                >
                  <select
                    className="large"
                    aria-label={language.labels.live.select_pathway}
                    onChange={(e) => {
                      pickPathway(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      {language.labels.live.select_pathway}
                    </option>
                    {mapped_pathways}
                  </select>
                </div>
              ) : (
                <div
                  className="page-section"
                  style={{
                    marginTop: "0.5em",
                    maxWidth: "66rem",
                    display: "flex",
                    gap: "1em",
                  }}
                >
                  <div className="glyphs alert" style={{ fontSize: "2em" }}>
                    !
                  </div>
                  <div>{language.labels.live.no_pathways}</div>
                </div>
              )}

              {/* select bites */}
              {bites.length > 0 && (
                <div className="page-section" style={{ paddingTop: "0em" }}>
                  {pathwayDescription && (
                    <div className="quote-text" style={{ marginTop: "0.5em" }}>
                      {pathwayDescription}
                    </div>
                  )}

                  <div className="card-long" style={{ display: "block" }}>
                    <div className="text-label">
                      {language.labels.live.pick_bites}
                    </div>
                    {mapped_bites}
                  </div>
                </div>
              )}

              {/* start stream */}
              <div className="page-section" style={{ paddingBottom: "6em" }}>
                {pid && (
                  <button
                    style={{
                      alignSelf: "flex-start",
                      marginTop: "0.5em",
                      fontWeight: "500",
                    }}
                    className={
                      selectedBites.length > 0 ? "button" : "button-cancel"
                    }
                    onClick={startStream}
                    disabled={selectedBites.length === 0}
                  >
                    {language.labels.live.start}
                  </button>
                )}
              </div>
            </>
          )}
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};

export default PathwayStream;
