import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";
import config from "../../config";
import axiosCall from "../../lib/axios";

const NewKnowledge = ({ language, signout, pageRefs, pageFunctions }) => {
  //initialize
  const auth = useSelector((state) => state.authentication.value);
  const navigate = useNavigate();

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [level, setLevel] = useState(-1);

  const [about, setAbout] = useState("");
  const [aboutErr, setAboutErr] = useState("");

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      setLevel(config.levels.indexOf(role));
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  ////////////// FUNCTIONS /////////////////
  const findBlocks = async () => {
    setAboutErr("");

    if (about.trim() === "") {
      setAboutErr(language.labels.knowledge.error.about);
      document.getElementById("about_field").focus();
    } else {
      // find top matches
      let result = await axiosCall("block/compare", {
        text: about.trim(),
        quick: true,
      });
      if (result.success) {
        if (result.data.similar.length > 0) {
          navigate("/newbite", {
            state: {
              preamble: about,
              referredBy: "knowledge",
              similar: result.data.similar,
            },
          });
        } else {
          navigate("/newbite", {
            state: { preamble: about, referredBy: "knowledge" },
          });
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        findBlocks();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  ////////////// GUI /////////////////
  if (level < 2) {
    return <></>;
  } else {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex="0">
          <div className="page-section" style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={language.labels.aria.logo.replace(
                  /{org}/g,
                  community.title
                )}
                className="menu-crest"
                style={{
                  height: "3em",
                  width: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + community.crest
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                  {language.labels.knowledge.add}
                </h1>
                <div className="handle2">{community.title}</div>
              </div>
            </div>
          </div>

          <div className="page-section" style={{ maxWidth: "44rem" }}>
            <h2
              className="heading2"
              style={{ fontWeight: "500", fontSize: "1.1em" }}
            >
              {language.labels.knowledge.about}
            </h2>
            <div style={{ marginTop: "0.5em" }}>
              {language.labels.knowledge.about_summary}
            </div>

            <TextareaAutosize
              id="about_field"
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
              placeholder={language.labels.knowledge.about_enter}
              aria-placeholder={language.labels.knowledge.about_enter}
              minRows="3"
              style={{ width: "100%" }}
              maxLength={config.string.paragraph}
            />

            {aboutErr && (
              <div className="errtext" role="alert">
                {aboutErr}
              </div>
            )}

            <div style={{ display: "flex", margin: "1.5em 0" }}>
              <button className="button" onClick={() => findBlocks()}>
                {language.labels.app.action_next}
              </button>
            </div>
          </div>
        </main>
      </div>
    );
  }
};

export default NewKnowledge;
