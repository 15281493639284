import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";
import axiosCall from "../../lib/axios";

import config from "../../config";

const NewPathway = ({ language, signout, pageRefs, pageFunctions }) => {
  //////////// INITIALIZATION ///////////
  const auth = useSelector((state) => state.authentication.value);

  const topicRef = useRef();
  const descriptionRef = useRef();

  const navigate = useNavigate();

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [level, setLevel] = useState(-1);

  const [topic, setTopic] = useState("");
  const [topicErr, setTopicErr] = useState("");

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      setLevel(config.levels.indexOf(role));
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  ////////////// PROCESS DATA /////////////////
  const checkTopic = () => {
    setTopicErr("");
    if (topic.trim() === "") {
      setTopicErr(language.labels.bites.error.bite);
      topicRef.current.focus();
    } else {
      createPathway(topic.trim());
    }
  };

  const createPathway = async (pathway) => {
    //call api
    let data = { pathway };
    if (descriptionRef.current.value.trim())
      data["description"] = descriptionRef.current.value.trim();
    let result = await axiosCall("pathway/create", data);
    if (result.success) {
      navigate("/pathway", { state: { pid: result.data } });
    } else if (result.refresh) {
      //token has been refreshed, try again
      createPathway(pathway);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// GUI /////////////////
  if (level < 2) {
    return <></>;
  } else {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex="0">
          <div className="page-section" style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={language.labels.aria.logo.replace(
                  /{org}/g,
                  community.title
                )}
                className="menu-crest"
                style={{
                  height: "3em",
                  width: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + community.crest
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                  {language.labels.pathways.add.one}
                </h1>
                <div className="handle2">{community.title}</div>
              </div>
            </div>
          </div>

          <div className="page-section" style={{ maxWidth: "66rem" }}>
            <div
              style={{ marginTop: "0.5em" }}
              dangerouslySetInnerHTML={{
                __html: language.labels.pathways.about,
              }}
            />

            {/* help generate topics */}
            <button
              style={{
                alignSelf: "flex-start",
                marginTop: "0.5em",
                fontWeight: "500",
              }}
              className="link"
              onClick={() => {
                navigate("/theme");
              }}
            >
              {language.labels.ai.help}
            </button>
          </div>

          {/* create new pathway */}
          <div className="page-section" style={{ maxWidth: "44rem" }}>
            <h2
              className="heading2"
              style={{
                fontWeight: "bold",
                fontSize: "1.1em",
                marginTop: "1em",
              }}
            >
              {language.labels.pathways.create}
            </h2>
            <input
              ref={topicRef}
              style={{ marginTop: "0.5em", width: "100%" }}
              placeholder={language.labels.tips.enter}
              aria-placeholder={language.labels.tips.enter}
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              maxLength={config.string.title}
            />
            {topicErr && (
              <div className="errtext" role="alert">
                {topicErr}
              </div>
            )}
            <div
              className="text-label"
              style={{
                marginTop: "1em",
              }}
            >
              {language.labels.tips.enter_description}
            </div>
            <input
              ref={descriptionRef}
              style={{ width: "100%" }}
              placeholder={language.labels.tips.pathway_description}
              maxLength={config.string.email}
            />
            <div style={{ marginTop: "0.5em", display: "flex", gap: "0.25em" }}>
              <button
                className="button-secondary"
                style={{ margin: "1em 0" }}
                onClick={() => navigate("/pathways")}
              >
                {language.labels.app.cancel}
              </button>
              <button
                className="button"
                style={{ margin: "1em 0", alignSelf: "flex-start" }}
                onClick={() => checkTopic()}
              >
                {language.labels.app.action_create}
              </button>
            </div>
          </div>
        </main>
      </div>
    );
  }
};

export default NewPathway;
