import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import config from "../../../config";
import axiosCall from "../../../lib/axios";
import { sortArrObj } from "../../../lib/common";
import CommunityPublic from "../community/public";

const Invite = ({ language, auth, signout, params }) => {
  ///////////////// INITIALIZE /////////////////
  const navigate = useNavigate();

  const [declined, setDeclined] = useState(false);
  const [accepted, setAccepted] = useState(false);

  ///////////////// FUNCTIONS /////////////////
  const direct = () => {
    if (auth.uid) {
      navigate("/hub");
    } else {
      navigate("/yongo");
    }
  };

  const accept = async () => {
    let result = await axiosCall("invite/accept", {
      code: params.code,
      cid: params.cid,
    });
    if (result.success) {
      sessionStorage.removeItem("invitation");
      setAccepted(true);
      if (result.status === 200) {
        //update communities
        let comm = result.data.community;
        let newcomm = {
          role: result.data.role,
          id: comm._id,
          title: comm.title,
          type: comm.type,
          uname: comm.uname,
          crest: comm.crest,
          description: comm.description,
          points: comm.points || 0,
          private: comm.private || false,
        };
        if (comm.exclusive) newcomm.exclusive = comm.exclusive;
        if (comm.verified) newcomm.verified = comm.verified;
        if (comm.website) newcomm.private = comm.website;

        if (localStorage.getItem("communities")) {
          let _communities = JSON.parse(localStorage.getItem("communities"));
          _communities.push(newcomm);
          _communities.sort(sortArrObj("uname", language.lang));
          localStorage.setItem("communities", JSON.stringify(_communities));
        } else {
          localStorage.setItem("communities", JSON.stringify([newcomm]));
        }
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      accept();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const decline = async (home = false) => {
    try {
      const configurationObject = {
        url: config.server.api + "/invite/decline",
        method: "POST",
        data: { code: params.code, cid: params.cid },
      };
      document.getElementById("loader").style.display = "inline";
      await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      sessionStorage.removeItem("invitation");
      if (home) {
        direct();
      } else {
        setDeclined(true);
      }
    } catch (err) {
      params.setErrMsg(language.labels.error.unknown);
      document.getElementById("loader").style.display = "none";
      sessionStorage.removeItem("invitation");
    }
  };

  ///////////////// RENDER GUI /////////////////
  return (
    <main
      className={auth.account_type ? "main-page" : "public"}
      tabIndex={0}
      style={{ paddingBottom: "2em" }}
    >
      <div
        className={auth.account_type ? "page-section" : "public-section"}
        style={{ marginTop: `${auth.account_type ? "0" : "1em"}` }}
      >
        {/* heading */}
        <div
          className={auth.account_type ? "mobile-justify" : "web-justify"}
          style={{
            display: "flex",
            fontWeight: 500,
            gap: "0.5em",
            alignItems: "center",
          }}
        >
          <div className="glyphs font-yongo" style={{ fontSize: "2em" }}>
            E
          </div>
          <div style={{ fontSize: "1.4em" }}>
            {language.labels.app.invitation}
          </div>
        </div>

        {params.errMsg && (
          <div
            className={
              auth.account_type ? "errtext mobile-center" : "errtext web-center"
            }
            style={{ marginTop: "2em" }}
          >
            <div>{params.errMsg}</div>
            <button
              className="button"
              style={{ marginTop: "2em" }}
              onClick={direct}
            >
              {language.labels.app.ok}
            </button>
          </div>
        )}

        {!params.errMsg && params.context._id && params.invitation._id && (
          <>
            <div
              className={auth.account_type ? "mobile-center" : "web-center"}
              style={{ marginTop: "1em" }}
            >
              {language.labels.invitation.invited[
                params.invitation.role
              ].replace(/{community}/g, params.context.uname)}
            </div>

            {/* community public details */}
            <CommunityPublic
              community={params.context}
              language={language}
              auth={auth}
            />

            {/* accept / decline */}
            {!accepted && !declined && !params.member && (
              <div
                className={auth.account_type ? "mobile-center" : "web-center"}
                style={{ marginTop: "2em" }}
              >
                {auth.account_type ? (
                  <button className="button" onClick={accept}>
                    {language.labels.invitation.accept}
                  </button>
                ) : (
                  <>
                    <div>{language.labels.invitation.accept_join}</div>
                    <button
                      className="button"
                      style={{ marginTop: "1em" }}
                      onClick={() => navigate("/login")}
                    >
                      {language.labels.authentication.signin.yongo}
                    </button>

                    <div className="join-container">
                      <h2 className="heading2">
                        {language.labels.authentication.no_account}
                      </h2>
                      <div
                        role="link"
                        className="link"
                        style={{ padding: "0 0.25em" }}
                        onClick={() => navigate("/signup")}
                        onKeyUpCapture={(e) =>
                          e.key === "Enter" && navigate("/signup")
                        }
                        tabIndex={0}
                      >
                        {language.labels.authentication.signup.join}
                      </div>
                    </div>
                  </>
                )}

                {/* decline */}
                <div style={{ marginTop: "2em" }}>
                  <button className="button-cancel" onClick={decline}>
                    {language.labels.invitation.decline}
                  </button>
                </div>
              </div>
            )}

            {params.member && (
              <div
                className={auth.account_type ? "mobile-center" : "web-center"}
                style={{ marginTop: "2em" }}
              >
                <div>{language.labels.invitation.member}</div>
                <div style={{ marginTop: "1em" }}>
                  <button className="button" onClick={() => decline(true)}>
                    {language.labels.app.ok}
                  </button>
                </div>
              </div>
            )}

            {(accepted || declined) && (
              <div
                className={auth.account_type ? "mobile-center" : "web-center"}
                style={{ marginTop: "2em" }}
              >
                <div>
                  {accepted
                    ? language.labels.invitation.accepted
                    : language.labels.invitation.declined}
                </div>
                <button
                  className="button"
                  style={{ marginTop: "1em" }}
                  onClick={direct}
                >
                  {language.labels.app.ok}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
};
export default Invite;
