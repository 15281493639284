import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  client: "",
  agree_terms: "x",
  account_type: "",
  uid: "",
  username: "",
  community_id: "",
  role: "",
  admin: "",
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: { value: initialStateValue },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },

    logout: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
