import config from "../config";

const sortArrObj = (key, lang) => {
  return function innerSort(a, b) {
    let comparison = a[key].localeCompare(b[key], lang, {
      ignorePunctuation: true,
    });
    return comparison;
  };
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const sessionExpired = async () => {
  if (localStorage.getItem("last_updated")) {
    if (
      Date.now() - localStorage.getItem("last_updated") <=
      config.server.timeout
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const togglePassword = (e, state, setState) => {
  setState(!state);
  if (state) {
    e.innerText = "-";
  } else {
    e.innerText = ".";
  }
};

const url2Key = (url) => {
  const pre_pos = config.server.storage.bucket.length;
  const post_url = url.substring(pre_pos);
  const post_post = post_url.indexOf("?");
  return post_url.substring(0, post_post);
};

export { sortArrObj, formatBytes, sessionExpired, togglePassword, url2Key };
