import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

import KnowledgeEditor from "./editor";
import Answers from "./answers";
import ImageView from "../../modal/Image";
import config from "../../../config";
import validator from "../../../lib/validation";
import { naturalDate } from "../../../lib/date";

const KnowledgeThread = ({
  community,
  language,
  thread,
  HTML,
  answers,
  addAnswer,
  setAddAnswer,
  flagged,
  editor,
  setEditor,
  getThread,
  onChange,
  setDeleteModal,
  bite,
  signout,
}) => {
  //////////// INITIALIZATION ///////////
  const auth = useSelector((state) => state.authentication.value);
  console.log(auth);
  const level = config.levels.indexOf(community.role);

  const [threadStatus, setThreadStatus] = useState(null);
  const [images, setImages] = useState([]);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [answerImages, setAnswerImages] = useState([]);
  const [currentAnswerImage, setCurrentAnswerImage] = useState(0);
  const [answerViewerOpen, setAnswerViewerOpen] = useState(false);

  useEffect(() => {
    if (thread._id) {
      if (!thread.post) thread.post = "";
      setThreadStatus(thread.status);
      let _images = [];
      if (thread.private) {
        for (const img of thread.images) {
          _images.push({ src: img.signed_url, name: img.originalname });
        }
      } else {
        for (const img of thread.images) {
          _images.push({
            src: config.server.storage.cache + img.key,
            name: img.originalname,
          });
        }
      }
      setImages(_images);
    }
  }, [thread]);

  //////////// FUNCTIONS ///////////
  const openImageViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const closeAnswerImageViewer = () => {
    setAnswerImages([]);
    setCurrentAnswerImage(0);
    setAnswerViewerOpen(false);
  };

  //////////// RENDER GUI ///////////
  if (thread._id) {
    return (
      <>
        {editor._id ? (
          <KnowledgeEditor
            editor={editor}
            setEditor={setEditor}
            getThread={getThread}
            language={language}
            signout={signout}
          />
        ) : (
          <>
            {/* title */}
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5em" }}
            >
              {bite && (
                <div
                  className="glyphs font-contrast-hard"
                  style={{ fontSize: "1.6em" }}
                >
                  {language.labels.knowledge.icon[thread.knowledge_type]}
                </div>
              )}
              <h2
                className="heading"
                style={{ padding: "0.5em 0", fontSize: "1.2em" }}
              >
                {thread.title}
              </h2>
            </div>

            {/* post */}
            {thread.pdf && thread.status === "available" && (
              <div
                className="document"
                style={{
                  height: 0.9 * window.innerHeight,
                }}
              >
                <object
                  data={
                    thread?.signed_pdf
                      ? thread.signed_pdf +
                        "#view=fitH&toolbar=0&navpanes=0&scrollbar=1"
                      : config.server.storage.cache +
                        thread.pdf +
                        "#view=fitH&toolbar=0&navpanes=0&scrollbar=1"
                  }
                  width="100%"
                  height="100%"
                >
                  <iframe
                    src={
                      thread?.signed_pdf
                        ? thread.signed_pdf +
                          "#view=fitH&toolbar=0&navpanes=0&scrollbar=1"
                        : config.server.storage.cache +
                          thread.pdf +
                          "#view=fitH&toolbar=0&navpanes=0&scrollbar=1"
                    }
                    width="100%"
                    height="100%"
                  />
                </object>
              </div>
            )}

            {thread.status === "pending" && (
              <div>{language.labels.knowledge.pending}</div>
            )}

            {!thread.pdf && HTML !== "" && (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "100%",
                    whiteSpace: "pre-wrap",
                    maxWidth: "66rem",
                  }}
                  dangerouslySetInnerHTML={{ __html: HTML }}
                />
              </div>
            )}

            {/* images */}
            {!isViewerOpen && images.length > 0 && (
              <div style={{ margin: "1em -2px" }}>
                <div style={{ fontWeight: "500", margin: "0 2px" }}>
                  {language.labels.app.image_count.replace(
                    /{n}/g,
                    images.length
                  )}
                </div>
                {images.map((file, index) => (
                  <div
                    key={index}
                    className="message-image-container"
                    onClick={() => openImageViewer(index)}
                    role="img"
                    aria-label={file.name}
                  >
                    <img
                      className="message-image"
                      src={file.src}
                      aria-hidden="true"
                      alt={file.name}
                    />
                  </div>
                ))}
              </div>
            )}
            <ImageView
              images={images}
              currentImage={currentImage}
              setCurrentImage={setCurrentImage}
              show={isViewerOpen}
              onClose={closeImageViewer}
              language={language}
            />

            {/* processing images */}
            {!isViewerOpen &&
              threadStatus === "processing" &&
              images.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    gap: "1em",
                    padding: "1em 0",
                    fontSize: "1.1em",
                  }}
                >
                  <div>{language.labels.app.images_processing}</div>
                </div>
              )}

            {/* meta */}
            <div
              style={{
                display: "flex",
                marginTop: "1.5em",
                alignItems: "center",
                opacity: "0.8",
              }}
            >
              <div
                role="img"
                className="glyphs"
                style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                aria-label={language.labels.aria.reads}
                data-tooltip-id="read-tooltip"
                data-tooltip-content={language.labels.aria.reads}
              >
                u
              </div>
              <div style={{ fontSize: "0.8em" }}>
                {validator.bigNumber(thread.views)}
              </div>
              <div style={{ flexBasis: "2em" }}></div>
              <div
                role="img"
                className="glyphs"
                style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
                aria-label={language.labels.aria.likes}
                data-tooltip-id="like-tooltip"
                data-tooltip-content={language.labels.aria.likes}
              >
                O
              </div>
              <div style={{ fontSize: "0.8em" }}>
                {validator.bigNumber(thread.likes)}
              </div>
            </div>

            {/* poster */}
            {!flagged && (
              <div>
                <div
                  className="heading2"
                  style={{ marginTop: "1em", fontStyle: "italic" }}
                >
                  {language.labels.knowledge.by[thread.knowledge_type]}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    marginTop: "0.5em",
                    maxWidth: "288px",
                  }}
                >
                  <div
                    className="avatar med"
                    style={{
                      flexShrink: "0",
                      backgroundImage: `url(${
                        config.server.storage.bucket + thread.poster_image
                      })`,
                    }}
                  ></div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="name" style={{ fontSize: "1em" }}>
                      {thread.poster_name}
                    </div>
                    {thread.poster_endorsed && (
                      <div className="contrast" style={{ fontSize: "11px" }}>
                        {language.labels.app.expert}
                      </div>
                    )}
                    <div style={{ fontSize: "0.7em", marginTop: "2px" }}>
                      {naturalDate(
                        thread.created,
                        language.locale,
                        language.labels.date
                      )}
                    </div>
                  </div>
                </div>

                {!thread.pdf &&
                  (thread?.user === auth.uid ||
                    (thread.community === community.id && level > 0)) && (
                    <button
                      className="button-edit"
                      style={{ padding: "0 1em", marginTop: "1.5em" }}
                      title={
                        language.labels.knowledge.edit[thread.knowledge_type]
                      }
                      aria-label={
                        language.labels.knowledge.edit[thread.knowledge_type]
                      }
                      onClick={() => {
                        setAddAnswer(false);
                        setEditor(thread);
                      }}
                    >
                      <div aria-hidden="true" className="glyphs font-contrast">
                        w
                      </div>
                      <div style={{ padding: "0 .2rem" }}></div>
                      <div style={{ flexGrow: 0 }}>
                        {language.labels.knowledge.edit[thread.knowledge_type]}
                      </div>
                    </button>
                  )}

                {/* delete */}
                {(thread?.user === auth.uid ||
                  (thread.community === community.id && level > 0)) && (
                  <button
                    className="link"
                    style={{ marginTop: "1.5em", fontSize: "0.9em" }}
                    onClick={() => setDeleteModal(true)}
                  >
                    {language.labels.knowledge.delete[thread.knowledge_type]}
                  </button>
                )}
              </div>
            )}

            {/* answers */}
            {thread.knowledge_type === "question" && !flagged && (
              <div style={{ marginTop: "2.5em" }}>
                {answers.length === 0 ? (
                  <div style={{ fontWeight: "500" }}>
                    {language.labels.answer.none}
                  </div>
                ) : (
                  <Answers
                    community={community}
                    language={language}
                    answers={answers}
                    setEditor={setEditor}
                    setAnswerImages={setAnswerImages}
                    answerViewerOpen={answerViewerOpen}
                    setCurrentAnswerImage={setCurrentAnswerImage}
                    setAnswerViewerOpen={setAnswerViewerOpen}
                    privateCommunity={thread.private}
                    getThread={getThread}
                    onChange={onChange}
                    signout={signout}
                  ></Answers>
                )}
                {!addAnswer && (
                  <button
                    className="button"
                    style={{ marginTop: "1em" }}
                    onClick={() => setAddAnswer(true)}
                  >
                    {language.labels.answer.provide}
                  </button>
                )}
              </div>
            )}
            <ImageView
              images={answerImages}
              currentImage={currentAnswerImage}
              setCurrentImage={setCurrentAnswerImage}
              show={answerViewerOpen}
              onClose={closeAnswerImageViewer}
              language={language}
            />
          </>
        )}
        {/* tooltips */}
        <Tooltip id="read-tooltip" />
        <Tooltip id="like-tooltip" />
        <Tooltip id="answer-tooltip" />
      </>
    );
  } else {
    return <></>;
  }
};

export default KnowledgeThread;
