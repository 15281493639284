import axios from "axios";
import config from "../config";

const gohome = (reload = true) => {
  console.log(window.location.pathname.substring(1));

  if (
    !config.unwrapped_routes.includes(window.location.pathname.substring(1))
  ) {
    if (reload) {
      window.location.href = "/timedout";
    } else {
      window.location.href = "/yongo";
    }
  } else {
    console.log("unwrapped route");
  }
};

const logoff = async (cid, reload) => {
  console.log("LOGOFF CALLED CID ", cid);
  console.log("LOGOFF CALLED reload ", reload);

  try {
    if (document.getElementById("loader"))
      document.getElementById("loader").style.display = "none";
    const configurationObject = {
      url: `${config.server.api}/signout/hub/${cid}`,
      method: "GET",
      withCredentials: true,
      credentials: "include",
    };
    await axios(configurationObject);
    gohome(reload);
  } catch (error) {
    console.error(`LOGGED ERROR: \n`, error);
    gohome(reload);
  }
};

export { logoff, gohome };
