import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";
import config from "../../config";
import { KnowledgeViewer } from "../widgets";

const Thread = ({ language, signout, pageRefs, pageFunctions }) => {
  //////////// INITIALIZE ///////////
  const { state } = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const [currentThread, setCurrentThread] = useState({});
  const [removedAnswer, setRemovedAnswer] = useState(null);

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      if (!currentThread._id) setCurrentThread(state.thread);
    } catch (err) {
      console.log(err);
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  ////////////// FUNCTIONS //////////////////

  const closeThread = () => {
    navigate("/knowledge");
  };

  const removeKnowledge = (answer) => {
    if (answer) {
      setRemovedAnswer(answer);
    }
  };

  const navBite = () => {
    navigate("/bite", {
      state: { bid: currentThread.block, thread: currentThread },
    });
  };

  //////////// RENDER GUI ///////////
  if (currentThread._id) {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          {/* heading */}
          <div className="page-section">
            <h1 className="heading">
              {language.labels.knowledge.type[currentThread.knowledge_type]}
            </h1>
          </div>

          {/* block */}
          {!state.flagged && (
            <div className="page-section">
              <h2 className="heading2" style={{ marginBottom: "0.25em" }}>
                {language.labels.videos.bite}
              </h2>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <div
                  role="img"
                  aria-label={currentThread.bite_name}
                  className="menu-crest"
                  style={{
                    width: "3em",
                    height: "3em",
                    backgroundImage: `url("${
                      config.server.storage.bucket + currentThread.bite_image
                    }")`,
                  }}
                ></div>
                <div style={{ width: "0.5em" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "calc(100% - 2.5em)",
                  }}
                >
                  <div
                    role="link"
                    className="link clamped one"
                    style={{
                      fontWeight: 500,
                      fontSize: "1.2em",
                      margin: "0.25em 0",
                    }}
                    onClick={navBite}
                    onKeyUpCapture={(e) => e.key === "Enter" && navBite()}
                    tabIndex={0}
                  >
                    {currentThread.bite_name}
                  </div>
                  <div className="handle2" style={{ width: "100%" }}>
                    {community.title}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* view thread */}
          <div className="page-section">
            <KnowledgeViewer
              community={community}
              style={{ maxWidth: "1600px", paddingBottom: "2em" }}
              language={language}
              currentThread={currentThread._id}
              onClose={closeThread}
              onChange={removeKnowledge}
              onDelete={closeThread}
              removedAnswer={removedAnswer}
              flagged={state.flagged}
              report={state.report}
              signout={signout}
            ></KnowledgeViewer>
          </div>
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Thread;
