import { React, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../../layout";

import { getContext } from "../../../lib/signin";

import { Details, Preview, Image, Success } from "./blockSteps";
import axiosCall from "../../../lib/axios";
import { sessionExpired } from "../../../lib/common";
import config from "../../../config";

const NewBlocks = ({ language, signout, pageRefs, pageFunctions }) => {
  //initialize
  const auth = useSelector((state) => state.authentication.value);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const [topic, setTopic] = useState("");
  const [topicId, setTopicId] = useState("");
  const [bitesArr, setBitesArr] = useState([]);
  const [activeBites, setActiveBites] = useState([]);
  const [biteCount, setBiteCount] = useState(0);

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);

      setTopic(state.topic);
      setTopicId(state.topicId);
      setBitesArr(state.bitesArr);
      setActiveBites(state.bitesArr);
      setBiteCount(state.bitesArr.length);
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    //check state
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities") &&
      state?.topic &&
      state?.topicId &&
      state?.bitesArr &&
      state.bitesArr.length > 0
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      navigate("/pathways");
    }
  }, []);

  //props
  const [currBite, setCurrBite] = useState(0);
  const [description, setDescription] = useState("");
  const [duplicates, setDuplicates] = useState([]);
  const [duplicatesInc, setDuplicatesInc] = useState([]);
  const [equivalent, setEquivalent] = useState(false);
  const [vague, setVague] = useState(false);
  const [extraSections, setExtraSections] = useState([]);

  //steps
  const [currentStep, setCurrentStep] = useState(0);
  const [nextStep, setNextStep] = useState([]);
  const [dlp, setDlp] = useState("");

  /////////// CREATE BLOCK  ////////////////

  const createBlock = async (image) => {
    //deep copy bites and remove entry
    let pending = [...activeBites];
    pending.splice(0, 1);

    // try and submit data
    const data = {
      topic,
      pid: topicId,
      pending,
      title: bitesArr[currBite],
      description,
      thumb: image,
      extras: extraSections,
    };

    let result = await axiosCall("block/create", data);
    if (result.success) {
      if (result.status === 200) {
        setActiveBites(pending);
        skip("animateImage");
      } else {
        setDlp(result.data.dlp);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      createBlock(image);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// STEPS /////////////////
  const next = async (step) => {
    // check we haven't timed out first
    let timedout = await sessionExpired();

    if (timedout) {
      signout();
    } else {
      setNextStep(step);
      setTimeout(() => {
        setCurrentStep(step.step);
        setNextStep([]);
      }, 300);
    }
  };

  const skip = (page) => {
    if (currBite + 1 < biteCount) {
      setCurrBite(currBite + 1);
      setCurrentStep(0);
      setNextStep([]);
      setDescription("");
      setExtraSections([]);
      if (page !== "aboutAnimate") {
        document.getElementById(page).style.animationName = "slideout";
        next({ step: 0, direction: 1 });
      }
    } else {
      document.getElementById(page).style.animationName = "slideout";
      next({ step: 3, direction: 1 });
    }
  };

  const allSteps = [
    <Details
      language={language}
      animation={nextStep}
      next={next}
      skip={skip}
      signout={signout}
      props={{
        currBite,
        biteCount,
        bitesArr,
        topic,
        topicId,
        description,
        setDescription,
        extraSections,
        setExtraSections,
        setVague,
        setEquivalent,
        setDuplicates,
        setDuplicatesInc,
      }}
    />,
    <Preview
      language={language}
      animation={nextStep}
      duplicates={duplicates}
      duplicatesInc={duplicatesInc}
      community={community}
      next={next}
      skip={skip}
      signout={signout}
      props={{ vague, equivalent, topicId, communityPathways: [] }}
    />,
    <Image
      next={next}
      animation={nextStep}
      language={language}
      createBlock={createBlock}
      dlp={dlp}
      setDlp={setDlp}
      props={{
        bitesArr,
        currBite,
        description,
        extraSections,
        cid: community.id,
      }}
    />,
    <Success
      language={language}
      animation={nextStep}
      topicId={topicId}
      navigate={navigate}
    />,
  ];

  return (
    <div className="main" ref={pageRefs._main_}>
      <div
        className="sr-only"
        id="primary_focus"
        tabIndex={0}
        onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
      ></div>
      <div
        ref={pageRefs.skip_link}
        className="link sr-only"
        role="link"
        onClick={() => pageFunctions.skipLink(false)}
        onKeyUpCapture={(e) =>
          e.key === "Enter" && pageRefs.main_focus.current.focus()
        }
        onFocus={() => pageFunctions.skipLink()}
        onBlur={() => pageFunctions.skipLink(false)}
        tabIndex={0}
      >
        {language.labels.aria.skip}
      </div>
      <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
      <SideBar
        language={language}
        signout={signout}
        auth={auth}
        communities={communities.length}
        community={community}
        resetMenu={pageFunctions.resetMenu}
        ref={pageRefs.sidebar_ref}
      />{" "}
      <main className="main-page" ref={pageRefs.main_focus} tabIndex="0">
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                community.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.server.storage.bucket + community.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.bites.add}
              </h1>
              <div className="handle2">{community.title}</div>
            </div>
          </div>
        </div>

        {/* topic and current bite */}
        <div className="page-section">
          <h2
            className="heading2"
            style={{
              fontWeight: "bold",
              fontSize: "1.1em",
              marginBottom: "0.5em",
            }}
          >
            {topic}
          </h2>
          <div>
            {language.labels.bites.creation.bite_n
              .replace(/{x}/, currBite + 1)
              .replace(/{n}/, biteCount)}
          </div>
          <h2
            className="heading2"
            style={{
              fontWeight: "500",
              fontSize: "1.1em",
              fontStyle: "italic",
            }}
          >
            {bitesArr[currBite]}
          </h2>
        </div>

        <div>
          {allSteps[currentStep]}
          {nextStep && allSteps[nextStep.step]}
        </div>
      </main>
    </div>
  );
};

export default NewBlocks;
