import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { Tooltip } from "react-tooltip";

import Modal from "../../modal/Modal";
import config from "../../../config";
import axiosCall from "../../../lib/axios";
import validator from "../../../lib/validation";
import { naturalDate } from "../../../lib/date";

const VideoEditor = ({
  style,
  language,
  community,
  currentVideo,
  flagged = false,
  onDelete,
  onModify,
  onClose,
  bite,
  navigate,
  signout,
}) => {
  ////////////// INITIALIZE //////////////////
  const auth = useSelector((state) => state.authentication.value);
  console.log(auth);
  const level = config.levels.indexOf(auth.role);

  const [videoSource, setVideoSource] = useState(null);
  const [videoHeight, setVideoHeight] = useState("512px");
  const [description, setDesc] = useState(currentVideo?.description);
  const [showModal, setShowModal] = useState(false);
  const [confirmClosure, setConfirmClosure] = useState(false);

  const desc_field = useRef();

  useEffect(() => {
    if (community.id) {
      //set height
      if (currentVideo.aspect < 0.5625) {
        const vh = 288 / currentVideo.aspect;
        setVideoHeight(vh + "px");
      }

      //get source
      if (!videoSource) {
        if (currentVideo.private) {
          setSource();
        } else {
          setVideoSource(config.server.storage.cache + currentVideo.link);
        }
      }
    } else {
      navigate("/videos");
    }
  }, []);

  async function setSource() {
    let result = await axiosCall("video/signature", {
      vid: currentVideo._id,
    });
    if (result.success) {
      if (result.data) setVideoSource(result.data?.url);
    } else if (result.refresh) {
      //token has been refreshed, try again
      setSource();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  }

  ////////////// UPDATES //////////////////
  const changeDesc = async (e) => {
    let val = desc_field.current.value.trim();
    //check for changes
    if (val === currentVideo.description) {
      e.target.blur();
      return;
    }

    // try and submit data
    let result = await axiosCall("video/description", {
      vid: currentVideo._id,
      description: val,
    });
    if (result.success) {
      if (result.data.nModified && result.data.nModified == 1) {
        onModify(val);
        //timeout workaround to allow time before focus shift
        setTimeout(() => {
          e.target.blur();
        }, 100);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      changeDesc(e);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const resetDesc = (e) => {
    if (currentVideo.description) {
      setDesc(currentVideo.description);
    } else {
      setDesc("");
    }
    e.target.blur();
  };

  const setBlur = (name) => {
    //timeout workaround to allow time for focus to shift if keyboard is used instead of mouse
    setTimeout(() => {
      if (name !== document.activeElement.getAttribute("name")) {
        if (currentVideo.description) {
          setDesc(currentVideo.description);
        } else {
          setDesc("");
        }
      }
    }, 100);
  };

  const deleteVideo = async () => {
    let result = await axiosCall("video/delete", {
      vid: currentVideo._id,
    });
    if (result.success) {
      onDelete();
    } else if (result.refresh) {
      //token has been refreshed, try again
      deleteVideo();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// RENDER GUI //////////////////

  return (
    <div style={style}>
      {/* back */}
      {bite && (
        <button className="link" onClick={() => onClose()}>
          {language.labels.bites.video_all}
        </button>
      )}

      <div style={{ marginTop: "1em" }}>
        {/* player */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: videoHeight,
            marginTop: "1em",
          }}
        >
          <div
            style={{
              background: `${
                currentVideo.aspect > 0.5625 ? "black" : "transparent"
              }`,
              width: "288px",
              height: videoHeight,
              display: "flex",
              justifyContent: "center",
              position: "absolute",
            }}
          >
            <video
              style={{ maxWidth: "288px", height: "auto" }}
              controls
              webkit-playsInline
              playsInline
            >
              {videoSource && <source src={videoSource} type="video/mp4" />}
            </video>
          </div>
        </div>

        {/* description */}
        {(community.id === currentVideo?.community && level > 0) ||
        auth.uid === currentVideo?.user ? (
          <div
            className="natural-edit"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1em",
            }}
          >
            <TextareaAutosize
              minRows={2}
              style={{ width: "100%" }}
              ref={desc_field}
              name="description_field"
              value={description}
              onChange={(e) => setDesc(e.target.value)}
              onBlur={() => setBlur("description_field")}
              placeholder={language.labels.videos.description}
              aria-placeholder={language.labels.videos.description}
              maxLength={config.string.bio}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flex: 0,
                margin: "0 -0.5rem",
              }}
            >
              <div
                role="button"
                className="glyphs accept"
                name="description_field"
                title={language.labels.app.apply}
                aria-label={language.labels.app.apply}
                onBlur={() => setBlur("description_field")}
                onClick={(e) => {
                  changeDesc(e);
                }}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") && changeDesc(e)
                }
                tabIndex={0}
              >
                *
              </div>
              <div
                role="button"
                className="glyphs reject"
                name="description_field"
                style={{ fontSize: "0.7rem", margin: "0 0.25rem" }}
                title={language.labels.app.discard}
                aria-label={language.labels.app.discard}
                onBlur={() => setBlur("description_field")}
                onClick={(e) => {
                  resetDesc(e);
                }}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") && resetDesc(e)
                }
                tabIndex={0}
              >
                x
              </div>
            </div>
          </div>
        ) : (
          <div style={{ fontStyle: "italic", marginTop: "1em" }}>
            {currentVideo.description}
          </div>
        )}

        {/* meta */}
        <div
          style={{
            display: "flex",
            marginTop: "1.5em",
            alignItems: "center",
            opacity: "0.8",
          }}
        >
          <div
            role="img"
            className="glyphs"
            style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
            aria-label={language.labels.aria.watches}
            data-tooltip-id="watch-tooltip"
            data-tooltip-content={language.labels.aria.watches}
          >
            u
          </div>
          <div style={{ fontSize: "0.8em" }}>
            {validator.bigNumber(currentVideo.views)}
          </div>
          <div style={{ flexBasis: "2em" }}></div>
          <div
            role="img"
            className="glyphs"
            style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
            aria-label={language.labels.aria.likes}
            data-tooltip-id="like-tooltip"
            data-tooltip-content={language.labels.aria.likes}
          >
            O
          </div>
          <div style={{ fontSize: "0.8em" }}>
            {validator.bigNumber(currentVideo.likes)}
          </div>
        </div>

        {/* poster or processing */}
        {currentVideo.status === "processing" ? (
          <div className="heading2" style={{ marginTop: "1em" }}>
            {language.labels.videos.uploading.processing}
          </div>
        ) : (
          <>
            <h2
              className="heading2"
              style={{ marginTop: "1em", fontStyle: "italic" }}
            >
              {language.labels.videos.by}
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                marginTop: "0.25em",
                maxWidth: "288px",
              }}
            >
              <div
                className="avatar med"
                style={{
                  flexShrink: "0",
                  backgroundImage: `url(${
                    config.server.storage.bucket + currentVideo.poster_image
                  })`,
                }}
              ></div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="name" style={{ fontSize: "1em" }}>
                  {currentVideo.poster_name}
                </div>
                {currentVideo.poster_endorsed && (
                  <div className="contrast" style={{ fontSize: "11px" }}>
                    {language.labels.app.expert}
                  </div>
                )}
                <div style={{ fontSize: "0.7em", marginTop: "2px" }}>
                  {naturalDate(
                    currentVideo.created,
                    language.locale,
                    language.labels.date
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {!flagged &&
          ((currentVideo.status != "processing" &&
            community.id === currentVideo.bite_community &&
            level > 0) ||
            (community.id === currentVideo?.community && level > 0) ||
            auth.uid === currentVideo?.user) && (
            <button
              className="link"
              style={{ marginTop: "2.5em" }}
              onClick={() => setShowModal(true)}
            >
              {language.labels.videos.delete_this}
            </button>
          )}
      </div>

      {/* DELETE VIDEO MODAL */}
      <Modal
        className="modal-dynamic"
        title={language.labels.videos.delete}
        show={showModal}
      >
        <div
          style={{ fontSize: "1.1em" }}
          dangerouslySetInnerHTML={{
            __html: language.labels.app.delete_warning,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: ".9rem",
            marginTop: "1rem",
          }}
        >
          <input
            id="consequence_understood"
            type={"checkbox"}
            onChange={(e) => setConfirmClosure(e.target.checked)}
            defaultChecked={confirmClosure}
          />
          <div style={{ minWidth: "0.5rem" }}></div>
          <label className="hover" htmlFor="consequence_understood">
            {language.labels.app.consequences_understood}
          </label>
        </div>
        {confirmClosure && (
          <div style={{ display: "flex", marginTop: "0.5em" }}>
            <button className="button" onClick={() => deleteVideo()}>
              {language.labels.videos.delete_now}
            </button>
          </div>
        )}
        <div style={{ display: "flex", marginTop: "2em" }}>
          <button className="button-off" onClick={() => setShowModal(false)}>
            {language.labels.app.cancel}
          </button>
        </div>
      </Modal>

      {/* tooltips */}
      <Tooltip id="watch-tooltip" />
      <Tooltip id="like-tooltip" />
    </div>
  );
};

export default VideoEditor;
