import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../../layout";

import { getContext } from "../../../lib/signin";
import axiosCall from "../../../lib/axios";
import config from "../../../config";
import { duration, preciseDate } from "../../../lib/date";
import { sortArrObj } from "../../../lib/common";
import StreamViewers from "../../widgets/live/Viewers";

const LiveStream = ({
  language,
  pageHeight,
  signout,
  pageRefs,
  pageFunctions,
}) => {
  //////////// INITIALIZE ///////////
  const auth = useSelector((state) => state.authentication.value);

  const { state } = useLocation();
  const navigate = useNavigate();

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [stream, setStream] = useState({});
  const [str, setStr] = useState(null); //stringify stream to update on attributes change
  const [bites, setBites] = useState([]);
  const [recordings, setRecordings] = useState([]);
  const [recent, setRecent] = useState(false);
  const [videoKey, setVideoKey] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [showViewers, setShowViewers] = useState(false);
  const [viewers, setViewers] = useState([]);

  const killPing = useRef();
  const vidRef = useRef();

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      getStream();
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  useEffect(() => {
    if (videoKey) getSignature();
  }, [videoKey]);

  useEffect(() => {
    if (videoSrc) vidRef.current?.load();
  }, [videoSrc]);

  const getStream = async () => {
    if (state?.streamId) {
      //get stream
      let result = await axiosCall("live/streaminfo", {
        streamId: state.streamId,
      });
      if (result.success) {
        fixRecordings(result.data.recordings);
        setBites(result.data.bites);
        pingCheck(result.data.stream);
      } else if (result.refresh) {
        //token has been refreshed, try again
        getStream();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  const fixRecordings = (arr) => {
    if (arr.length > 0) {
      arr.sort(sortArrObj("LastModified", language.lang));
      setRecordings(arr);
    }
  };

  ////////////// ACTIONS //////////////////
  const joinStream = async () => {
    let state = {
      streamId: stream._id,
      streamName: stream.streamName,
      pathway: stream.pathwayTitle,
      streamStarted: stream.started,
      bitesArr: bites,
    };
    navigate("/playstream", { state });
  };

  const resumeStream = async () => {
    let state = {
      resume: stream._id,
      streamName: stream.streamName,
      pathway: stream.pathwayTitle,
      pid: stream.pathway,
      bites,
      selectedBites: stream.bites,
    };
    navigate("/pubstream", { state });
  };

  const killStream = async () => {
    // retrieve token
    let result = await axiosCall("live/kill", { streamId: state.streamId });
    console.log(result);
    if (result.success) {
      navigate("/livestreams");
    } else if (result.refresh) {
      //token has been refreshed, try again
      killStream();
    } else {
      //refresh token expired or unknown error
      // signout();
    }
  };

  const playback = async (e) => {
    try {
      setVideoKey(e.id);
    } catch (err) {}
  };

  const quitPlayback = () => {
    setVideoKey(null);
    setVideoSrc(null);
  };

  const getSignature = async () => {
    console.log(videoKey);
    if (state?.streamId) {
      //get stream
      let result = await axiosCall("live/signature", {
        streamId: state.streamId,
        key: videoKey,
      });
      if (result.success) {
        console.log(result.data);
        // console.log(vidRef.current);
        // vidRef.current.src = result.data.url;
        setVideoSrc(result.data.url);
      } else if (result.refresh) {
        //token has been refreshed, try again
        getSignature();
      } else {
        //refresh token expired or unknown error
        console.log(result);
        // signout();
      }
    }
  };

  ////////////// PING //////////////////
  const pingCheck = async (s) => {
    if (window.location.pathname === "/livestream") {
      //set stream
      setStream(s);
      setStr(JSON.stringify(s));

      // determine if recently ended
      let _recent = false;
      if (s.ended) {
        _recent =
          new Date() - new Date(s.ended) < config.expiration_ms.recent_live;
        setRecent(_recent);
      }

      // ping after 5 seconds
      if (!killPing.current) {
        if (_recent || !s.ended) {
          setTimeout(() => {
            pingStream(s);
          }, 5000);
        }
      }
    }
  };

  const pingStream = async (s) => {
    if (window.location.pathname === "/livestream") {
      //update started, stopping, duration and ended if changed
      const ping = await getPing();
      if (ping.started) s.started = ping.started;
      if (ping.stopping) {
        s.stopping = ping.stopping;
      } else {
        delete s.stopping;
      }
      if (ping.duration) s.duration = ping.duration;
      if (ping.ended) {
        s.ended = ping.ended;
      } else {
        delete s.ended;
      }
      if (ping.live) {
        s.live = ping.live;
      } else {
        s.live = false;
      }

      //loop
      pingCheck(s);
    }
  };

  const getPing = async (attempt = 1) => {
    try {
      let result = await axiosCall(
        "live/ping",
        { streamId: state.streamId },
        false
      );
      if (result.success) {
        return result.data;
      } else if (result.refresh) {
        throw new Error();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } catch (err) {
      if (attempt < 2) {
        return getPing(attempt + 1);
      } else {
        //unknown error
        signout();
      }
    }
  };

  ////////////// RENDER GUI //////////////////
  const mappedRecordings = recordings.map((recording, index) => (
    <div
      style={{
        display: "flex",
        gap: "0.5em",
        textAlign: "center",
        marginTop: "0.75em",
      }}
      key={index}
    >
      <div className="glyphs contrast" style={{ fontSize: "1.5em" }}>
        v
      </div>
      <button
        className="link"
        id={recording.Key}
        onClick={(e) => playback(e.target)}
        style={{ fontSize: "1.1em" }}
      >
        {recordings.length > 1
          ? language.labels.live.recording_part.replace(/{n}/g, index + 1)
          : language.labels.live.recording_play}
      </button>
    </div>
  ));

  if (stream.publisher) {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          {/* heading */}
          <div className="page-section">
            <h1 className="heading">{language.labels.live.learning_stream}</h1>
          </div>

          {/* publisher */}
          <div className="page-section">
            <div style={{ display: "flex", gap: "0.5em" }}>
              <div
                role="img"
                aria-label={stream.username}
                className="thumb-block auto-margin-narrow"
                style={{
                  backgroundImage: `url("${
                    config.server.storage.bucket + stream.avatar
                  }")`,
                  borderRadius: "50%",
                }}
              ></div>
              <div>
                <h2
                  className="heading"
                  style={{ fontSize: "1.2em", margin: "0.2em 0" }}
                >
                  {stream.streamName}
                </h2>
                <div>
                  {language.labels.live.host.replace(/{name}/, stream.username)}
                </div>
                {stream.fullname && (
                  <div className="handle2" style={{ marginTop: "0.1em" }}>
                    {stream.fullname}
                  </div>
                )}
              </div>
            </div>
          </div>

          {videoKey ? (
            <div className="page-section">
              <video
                ref={vidRef}
                style={{
                  maxWidth: "44rem",
                  maxHeight: "640px",
                  width: "auto",
                  height: "auto",
                }}
                controls
                webkit-playsInline
                playsInline
              >
                <source src={videoSrc} type="video/mp4" />
              </video>

              <button
                className="button"
                style={{ display: "block", margin: "1em 0" }}
                onClick={quitPlayback}
              >
                {language.labels.app.action_back}
              </button>
            </div>
          ) : showViewers ? (
            <div className="page-section">
              <StreamViewers
                language={language}
                signout={signout}
                pageHeight={pageHeight}
                params={{
                  streamId: state.streamId,
                  viewers,
                  setViewers,
                  setShowViewers,
                }}
              />
            </div>
          ) : (
            <div className="page-section">
              {/********* DETAILS  *********/}
              <div
                className="card-long"
                style={{
                  display: "block",
                }}
              >
                {/* status */}
                <h2
                  className={
                    stream.ended
                      ? "heading2 middle"
                      : stream.stopping
                      ? "heading2 amber flashing"
                      : stream.timestamp
                      ? "heading2 alert"
                      : "heading2 amber flashing"
                  }
                  style={{ fontWeight: 500, fontSize: "1.2em" }}
                >
                  {stream.ended
                    ? language.labels.live.streaming.ended
                    : stream.stopping
                    ? language.labels.live.streaming.ending
                    : stream.timestamp
                    ? language.labels.live.streaming.now
                    : language.labels.live.streaming.pending}
                </h2>

                {/* start time */}
                <div style={{ fontWeight: 300, marginTop: "1em" }}>
                  {language.labels.live.started.replace(
                    /{date}/,
                    preciseDate(stream.started, language.locale)
                  )}
                </div>

                {/* end time and duration */}
                {stream.ended && (
                  <>
                    <div style={{ fontWeight: 300, marginTop: "0.5em" }}>
                      {language.labels.live.ended_time.replace(
                        /{time}/,
                        preciseDate(stream.ended, language.locale)
                      )}
                    </div>

                    <div style={{ fontWeight: 300, marginTop: "0.5em" }}>
                      {language.labels.live.duration.replace(
                        /{time}/g,
                        duration(stream.duration / 1000)
                      )}
                    </div>
                  </>
                )}

                {/* auto status */}
                {!stream.ended && (stream.stopping || !stream.timestamp) && (
                  <div style={{ paddingTop: "1em" }}>
                    {language.labels.live.status_update}
                  </div>
                )}

                {/* action buttons */}
                <div
                  style={{
                    display: "flex",
                    gap: "0.5em",
                    marginTop: "1.5em",
                    fontSize: "0.9em",
                  }}
                >
                  {recent && stream.ended && stream.publisher === auth.uid && (
                    <button className="button narrow" onClick={resumeStream}>
                      {language.labels.live.resume}
                    </button>
                  )}
                  {!stream.stopping &&
                    !stream.ended &&
                    stream.live &&
                    stream.timestamp && (
                      <button className="button narrow" onClick={joinStream}>
                        {language.labels.live.join}
                      </button>
                    )}
                  {!stream.ended && stream.publisher === auth.uid && (
                    <button className="button alert" onClick={killStream}>
                      {stream.stopping || !stream.timestamp
                        ? language.labels.live.kill
                        : language.labels.live.end}
                    </button>
                  )}
                  {stream.ended && (
                    <button
                      className="button-secondary narrow"
                      onClick={() => setShowViewers(true)}
                    >
                      {language.labels.live.viewers.button}
                    </button>
                  )}
                </div>
              </div>

              {/********* RECORDINGS  *********/}
              {recordings.length > 0 && (
                <div
                  className="card-long"
                  style={{
                    display: "block",
                    paddingBottom: "2em",
                  }}
                >
                  <h2
                    className="heading2"
                    style={{ fontWeight: 300, fontSize: "1.2em" }}
                  >
                    {recordings.length === 1
                      ? language.labels.live.recording
                      : language.labels.live.recordings}
                  </h2>
                  {mappedRecordings}
                </div>
              )}

              {/********* FOCUS  *********/}
              <div
                className="card-long"
                style={{
                  display: "block",
                  paddingBottom: "2em",
                }}
              >
                <h2
                  className="heading2"
                  style={{ fontWeight: 300, fontSize: "1.2em" }}
                >
                  {language.labels.live.focus}
                </h2>
                {/* pathway */}
                <div
                  style={{
                    display: "flex",
                    gap: "0.5em",
                    alignItems: "center",
                    margin: "1.5em 0 1em",
                  }}
                >
                  <div
                    role="img"
                    className="thumb-glyph tiny auto-margin-narrow"
                  >
                    p
                  </div>
                  <div style={{ fontWeight: 500, flex: 1 }}>
                    {stream.pathwayTitle}
                  </div>
                </div>

                {/* bites */}
                {bites.map((bite, index) => (
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5em",
                      alignItems: "center",
                      marginTop: "0.5em",
                    }}
                  >
                    <div
                      role="img"
                      className="thumb-block border tiny auto-margin-narrow"
                      style={{
                        backgroundImage: `url("${
                          config.server.storage.bucket + bite.thumb
                        }")`,
                      }}
                    ></div>
                    <div
                      className="quote-text"
                      style={{ fontSize: "0.9em", flex: 1 }}
                    >
                      {bite.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};

export default LiveStream;
