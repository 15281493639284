import { React, useEffect } from "react";

const PageNotFound = ({ language }) => {
  return (
    <main className="login">
      <h1 className="heading">{language.labels.app.page_not_found}</h1>
    </main>
  );
};

export default PageNotFound;
