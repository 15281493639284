import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../layout";

import config from "../../config";
import axiosCall from "../../lib/axios";
import { getContext } from "../../lib/signin";
import { naturalDate } from "../../lib/date";

import Answers from "../widgets/knowledge/answers";
import ImageView from "../modal/Image";
import Modal from "../modal/Modal";

const AnswerFlag = ({ language, signout, pageRefs, pageFunctions }) => {
  //////////// INITIALIZE ///////////
  const { state } = useLocation();
  const auth = useSelector((state) => state.authentication.value);
  const navigate = useNavigate();

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const [actions, setActions] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(state?.report.status);
  const [status, setStatus] = useState("");
  const [answer, setAnswer] = useState({});
  const [fromKnowledge, setFromKnowledge] = useState({});
  const [reporter, setReporter] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editor, setEditor] = useState({});
  const [answerImages, setAnswerImages] = useState([]);
  const [answerViewerOpen, setAnswerViewerOpen] = useState(false);
  const [currentAnswerImage, setCurrentAnswerImage] = useState(0);
  const [confirmClosure, setConfirmClosure] = useState(false);
  const [success, setSuccess] = useState(false);

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      getAnswer();
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (!state?.thread) {
      navigate("/yongo");
    } else if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  useEffect(() => {
    switch (currentStatus) {
      case "accepted":
        setActions(["restore"]);
        break;
      case "pending":
        setActions(["reject", "remove"]);
        break;
      case "rejected":
        setActions(["pending"]);
        break;
      default:
        setActions(["pending", "reject", "remove"]);
        break;
    }
  }, [currentStatus]);

  const getAnswer = async () => {
    const data = { aid: state.thread._id, reporter: state.report.reporter };

    // console.log("DATA", data);
    let result = await axiosCall("report/get/answer", data);
    if (result.success) {
      // console.log(result);
      setAnswer(result.data.answer);
      setFromKnowledge(result.data.fromKnowledge);
      setReporter(result.data.reporter);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getAnswer();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const closeAnswerImageViewer = () => {
    setAnswerImages([]);
    setCurrentAnswerImage(0);
    setAnswerViewerOpen(false);
  };

  const updateStatus = async (selectedStatus) => {
    let data = {
      action: selectedStatus,
      aid: state.thread._id,
    };

    let result = await axiosCall("report/settle/knowledge", data);
    if (result.success) {
      navigate(".", {
        state: {
          thread: {
            _id: state.thread._id,
          },
          flagged: true,
          report: { ...state.report, status: result.data },
        },
      });
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } else if (result.refresh) {
      //token has been refreshed, try again
      updateStatus(selectedStatus);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const takeAction = (action) => {
    if (action === "remove") {
      //do a popup that warns "are you sure etc..."
      //have an action on the modal so that if the admin cancels,
      setStatus("remove");
      setShowModal(true);
    } else {
      //change the status, update the page. stay on page
      setStatus(action);
      if (action === "reject" || action === "restore") {
        setCurrentStatus("rejected");
      } else {
        setCurrentStatus("pending");
      }
      updateStatus(action);
      setStatus("");
    }
  };

  const gotoThread = () => {
    navigate("/thread", { state: { thread: fromKnowledge } });
  };

  const deleteAnswer = async () => {
    let result = await axiosCall("knowledge/withdraw", {
      aid: state.thread._id,
    });
    if (result.success) {
      setStatus("");
      navigate("/flagged");
    } else if (result.refresh) {
      deleteAnswer();
    } else {
      signout();
    }
  };

  const canModal = () => {
    setShowModal(false);
    setStatus("");
  };

  ////////////// RENDER GUI //////////////////
  const mapped_actions = actions.map((action, index) => {
    return (
      <option value={action} key={index} selected={status === action}>
        {language.labels.flags.actions[action]}
      </option>
    );
  });

  if (Object.keys(answer).length > 0) {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          <div className="page-section">
            <div
              style={{
                display: "flex",
                marginTop: "1em",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <h2
                className="heading2"
                style={{ fontSize: "1.1em", fontWeight: "600" }}
              >
                {language.labels.flags.id.replace(/{id}/g, state.report._id)}
              </h2>
            </div>

            <div>{state.report.category}</div>

            <div className="quote-text" style={{ marginTop: "0.5em" }}>
              <div>{state.report.description}</div>
            </div>

            {/* poster */}
            <div className="heading2" style={{ marginTop: "1em" }}>
              {language.labels.flags.reported_by}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                marginTop: "0.5em",
                maxWidth: "288px",
              }}
            ></div>

            <div
              className="avatar med"
              style={{
                flexShrink: "0",
                backgroundImage: `url(${
                  config.server.storage.bucket + reporter.avatar
                })`,
              }}
            ></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="name" style={{ fontSize: "1em" }}>
                {reporter.username}
              </div>

              <div style={{ fontSize: "0.7em", marginTop: "2px" }}>
                {naturalDate(
                  state.report.created,
                  language.locale,
                  language.labels.date
                )}
              </div>
            </div>

            <div style={{ marginTop: "1em", display: "flex", gap: "0.5em" }}>
              <div>{language.labels.app.status}</div>
              <div style={{ fontWeight: "500" }}>
                {language.labels.flags.status[currentStatus]}
              </div>
            </div>

            {success && (
              <div
                style={{ fontWeight: 600, marginTop: "1em" }}
                className="success"
                role="alert"
              >
                {language.labels.flags.success}
              </div>
            )}

            {/* OPTIONS */}
            <div style={{ marginTop: "1em" }}>
              <select
                className="large"
                aria-label={language.labels.roles.role_choose}
                value={status}
                onChange={(e) => {
                  takeAction(e.target.value);
                }}
              >
                <option value="" selected disabled hidden>
                  {language.labels.flags.take_action}
                </option>
                {mapped_actions}
              </select>
            </div>
          </div>

          {/* FROM KNOWLEDGE */}
          <div
            className="page-section"
            style={{ maxWidth: "66rem", paddingBottom: "1em" }}
          >
            <h2
              className="heading2"
              style={{ fontSize: "1.1em", fontWeight: "600" }}
            >
              {language.labels.flags.from_question}
            </h2>
            <div
              role="figure"
              key={0}
              className="card-long hover"
              onClick={() => {
                gotoThread();
              }}
              onKeyDown={(e) => {}}
              tabIndex={0}
              aria-label={fromKnowledge.title}
              style={{ display: "block" }}
              id="FromKnowledge"
            >
              <div
                style={{
                  display: "flex",
                  gap: "0.5em",
                  alignItems: "center",
                  margin: "0.25em 0",
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${config.server.storage.bucket}${fromKnowledge.bite_image})`,
                    flexShrink: "0",
                  }}
                  className="thumb-block auto-margin-narrow"
                ></div>
                <div className="block-info">
                  <div
                    role="link"
                    className="clamped one"
                    style={{ fontWeight: "500", fontSize: "1.1em" }}
                    onKeyDown={(e) => {}}
                    tabIndex={0}
                  >
                    {fromKnowledge.title}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5em",
                      alignItems: "center",
                      margin: "0.25em 0",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    <div>{language.labels.videos.bite}</div>
                    <div
                      className="clamped one font-soft"
                      style={{ fontStyle: "italic" }}
                    >
                      {fromKnowledge.bite_name}
                    </div>
                  </div>
                  <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
                    {naturalDate(
                      fromKnowledge.created,
                      language.locale,
                      language.labels.date
                    )}
                  </div>
                  <div
                    style={{ fontSize: "11px" }}
                    dangerouslySetInnerHTML={{
                      __html: language.labels.app.posted_by.replace(
                        /{user}/g,
                        fromKnowledge.poster_name
                      ),
                    }}
                  />
                  <div
                    style={{ fontSize: ".9em", marginTop: "0.5em" }}
                    className={`quote-text clamped two`}
                  >
                    {fromKnowledge.post}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ANSWER */}
          <div className="page-section">
            <Answers
              community={community}
              language={language}
              answers={[answer]}
              setEditor={setEditor}
              setAnswerImages={setAnswerImages}
              answerViewerOpen={answerViewerOpen}
              setCurrentAnswerImage={setCurrentAnswerImage}
              setAnswerViewerOpen={setAnswerViewerOpen}
              privateCommunity={fromKnowledge.private}
              signout={signout}
            ></Answers>
          </div>
          {/* IMAGEVIEWER */}
          <ImageView
            images={answerImages}
            currentImage={currentAnswerImage}
            setCurrentImage={setCurrentAnswerImage}
            show={answerViewerOpen}
            onClose={closeAnswerImageViewer}
            language={language}
          />
          {/* DELETE ANSWER MODAL */}
          <Modal
            className="modal-dynamic"
            title={language.labels.knowledge.delete.answer}
            show={showModal}
          >
            <div
              style={{ fontSize: "1.1em" }}
              dangerouslySetInnerHTML={{
                __html: language.labels.app.delete_warning,
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: ".9rem",
                marginTop: "1rem",
              }}
            >
              <input
                id="consequence_understood"
                type={"checkbox"}
                onChange={(e) => setConfirmClosure(e.target.checked)}
                defaultChecked={confirmClosure}
              />
              <div style={{ minWidth: "0.5rem" }}></div>
              <label className="hover" htmlFor="consequence_understood">
                {language.labels.app.consequences_understood}
              </label>
            </div>
            {confirmClosure && (
              <div style={{ display: "flex", marginTop: "0.5em" }}>
                <button className="button" onClick={() => deleteAnswer()}>
                  {language.labels.knowledge.delete_now.answer}
                </button>
              </div>
            )}
            <div style={{ display: "flex", marginTop: "2em" }}>
              <button
                className="button-off"
                onClick={() => {
                  canModal();
                }}
              >
                {language.labels.app.cancel}
              </button>
            </div>
          </Modal>
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};

export default AnswerFlag;
