import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage
// import storage from "redux-persist/lib/storage/session"; // sessionStorage

import authReducer from "./features/authentication";

// Persist configuration for all slices
const persistConfig = {
  key: "root",
  storage,
};

// Apply persistReducer for all slices
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

// Create persisted store
const store = configureStore({
  reducer: {
    authentication: persistedAuthReducer,
  },
});

const persistor = persistStore(store);

export { store, persistor };
