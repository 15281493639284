import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../../../config";

const PubBar = ({ language, buttons }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);
  const userData = localStorage.getItem("basic") || "{}";

  return (
    <div className="topbar" style={{ display: "flex" }}>
      <div className="public bar">
        <div
          aria-hidden="true"
          className="glyphs hover"
          style={{ fontSize: "2.7em" }}
          onClick={() => navigate("/yongo")}
        >
          Y
        </div>
        <div style={{ fontSize: "1.8em", paddingBottom: "0.15em", flex: "1" }}>
          <span
            role="link"
            className="font-logo hover"
            onClick={() => navigate(`/yongo`)}
            onKeyUpCapture={(e) => e.key === "Enter" && navigate(`/yongo`)}
            tabIndex={0}
          >
            {language.labels.brand.name}
          </span>
        </div>
        {buttons && (
          <div style={{ padding: "0 0.5em" }}>
            {auth.account_type &&
            auth.account_type !== "suspended" &&
            auth.agree_terms === "ok" ? (
              <button
                className="avatar thumb"
                style={{
                  backgroundImage: `URL(${
                    config.server.storage.bucket + JSON.parse(userData).avatar
                  })`,
                  width: "42px",
                  height: "42px",
                  marginTop: "0.4em",
                }}
                onClick={() => navigate("/account")}
              />
            ) : (
              <button
                className="button pub"
                style={{ alignSelf: "flex-end", fontSize: "0.9em" }}
                onClick={() => navigate("/login")}
              >
                {language.labels.authentication.signin.user}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PubBar;
