import encryption from "../lib/encryption";

const getContext = async (id, role, communities) => {
  let community = {};
  for (let i = 0; i < communities.length; i++) {
    if (communities[i].id === id && communities[i].role === role) {
      community = communities[i];
      break;
    }
  }
  return community;
};

const localSignIn = async (obj) => {
  try {
    //set tag, client_id, last_update and communities
    const hash = encryption.encrypt(obj.jwt);
    localStorage.setItem("tag", hash);
    localStorage.setItem("last_updated", Date.now());
    localStorage.setItem("client_id", obj.client_id);
    localStorage.setItem("communities", JSON.stringify(obj.communities));

    //basic
    let _basic = {
      avatar: obj.basic.avatar,
      phone_number: obj.basic.phone_number,
      country: obj.basic.country || "-",
    };
    if (obj.basic.fullName) _basic.fullName = obj.basic.fullName;
    if (obj.basic.bio) _basic.bio = obj.basic.bio;
    localStorage.setItem("basic", JSON.stringify(_basic));

    //emails
    if (obj.emails) {
      localStorage.setItem("emails", JSON.stringify(obj.emails));
    } else {
      localStorage.setItem("emails", "[]");
    }

    return true;
  } catch (error) {
    console.error(`LOGGED ERROR: \n`, error);
    return false;
  }
};

export { getContext, localSignIn };
