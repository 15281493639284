import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { login } from "../../../../features/authentication";
import { localSignIn } from "../../../../lib/signin";
import config from "../../../../config";

const SuccessPage = ({ language, animation, def_focus, params }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    def_focus.current.focus();
    if (animation && animation.step === 3) {
      document.getElementById("success").style.animationName = "slidein";
    }
  }, []);

  const sign_me_in = async () => {
    try {
      const configurationObject = {
        url: `${config.server.api + "/auth/signin/hub"}`,
        method: "POST",
        data: { username: params.username, password: params.password },
        withCredentials: true,
        credentials: "include",
      };
      document.getElementById("loader").style.display = "inline";
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        const signin = await localSignIn(response.data);
        if (signin) authenticate(response.data);
      }
    } catch (error) {
      document.getElementById("loader").style.display = "none";
      console.error(`LOGGED ERROR: \n`, error);
    }
  };

  ////////////////// LOG IN //////////////////
  const authenticate = (obj) => {
    let loginState = {
      client: obj.client,
      agree_terms: obj.agree_terms,
      account_type: obj.basic.account_type,
      uid: obj.basic._id,
      username: obj.basic.username,
    };
    if (obj.community_id) loginState.community_id = obj.community_id;
    if (obj.role) loginState.role = obj.role;
    if (obj.admin) {
      loginState.admin = "Y";
    } else {
      loginState.admin = "";
    }
    dispatch(login(loginState));
    navigate("/hub");
  };

  ////////////////// RENDER GUI //////////////////
  return (
    <div className="signup" id="success">
      <div className="join-container">
        <div
          role="status"
          style={{ fontWeight: "500", fontSize: "1.1em" }}
          dangerouslySetInnerHTML={{
            __html: language.labels.authentication.registered,
          }}
        />
      </div>
      <button
        ref={def_focus}
        className="button"
        onClick={() => sign_me_in()}
        style={{ width: "100%", marginTop: "1.5rem", fontSize: "1.1em" }}
      >
        {language.labels.authentication.signin.now}
      </button>
    </div>
  );
};

export default SuccessPage;
