import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { login } from "../../features/authentication";

import { TopBar, SideBar } from "../layout";
import config from "../../config";

import { getContext } from "../../lib/signin";
import { switchCommunity } from "../../lib/community";
import encryption from "../../lib/encryption";
import navGrid from "../../lib/thumbGrid";

const CommunityHub = ({ language, signout, pageRefs, pageFunctions }) => {
  ////////////// INITIALIZE //////////////
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication.value);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const initialize = async (obj, id, role) => {
    try {
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      setCommunities(obj);
      if (auth.community_id && auth.role) {
        console.log("community", JSON.parse(localStorage.getItem("context")));
        initialize(obj, auth.community_id, auth.role);
      }
    } else {
      signout();
    }
  }, []);

  const SwitchCommunities = async (cid) => {
    if (cid) {
      let switcher = await switchCommunity(cid);
      if (switcher === "refresh") {
        //token has been refreshed, try again
        SwitchCommunities(cid);
      } else if (switcher === "error") {
        //refresh token expired or unknown error
        signout();
      } else {
        //set tag, client_id and last_update
        localStorage.setItem("last_updated", Date.now());
        const hash = encryption.encrypt(switcher.jwt);
        localStorage.setItem("tag", hash);

        //reset authorization
        let _auth = { ...auth };
        _auth.community_id = switcher.community_id;
        _auth.role = switcher.role;
        dispatch(login(_auth));

        navigate("/mycommunity");
      }
    }
  };

  ////////////// KEYBOARD INTERACTION //////////////////
  const keyAction = (e) => {
    //navigated grid
    if (e.target.tagName.toUpperCase() === "DIV") {
      //detect ctrl+key
      let key;
      if (e.ctrlKey || e.metaKey) {
        key = "CTRL+" + e.key;
      } else {
        key = e.key;
      }
      const _key = config.thumb_grid_keys.indexOf(key);
      if (_key >= 0) {
        let container = document.getElementById("thumb_wall");
        let contents = container.querySelectorAll("div[tabindex]");
        navGrid(container, contents, key);
      }
    }
  };

  ////////////// RENDER GUI //////////////////
  const communityList = communities.map((data, idx) => (
    <div
      role="figure"
      aria-label={data.title}
      className="thumb-group"
      key={idx}
      title={data.title}
      onKeyDown={(e) => keyAction(e)}
      tabIndex="0"
    >
      <div
        className="thumb-group-content thumb-hover"
        onClick={() => {
          SwitchCommunities(data.id);
        }}
      >
        <div
          role="img"
          className="thumb-block"
          aria-label={language.labels.aria.logo.replace(/{org}/g, data.title)}
          style={{
            marginBottom: "3px",
            backgroundImage: `url(${
              config.server.storage.bucket + data.crest
            })`,
          }}
        ></div>
        <button
          className="name"
          id={data.id}
          style={{ width: "100%", padding: "0 1em" }}
          onClick={() => {
            SwitchCommunities(data.id);
          }}
        >
          {data.uname}
        </button>
        <div className="handle2" style={{ width: "100%", padding: "0 1em" }}>
          {data.title}
        </div>
        <div>{language.labels.roles[data.role]}</div>
      </div>
    </div>
  ));

  return (
    <div className="main" ref={pageRefs._main_}>
      <div
        className="sr-only"
        id="primary_focus"
        tabIndex={0}
        onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
      ></div>
      <div
        ref={pageRefs.skip_link}
        className="link sr-only"
        role="link"
        onClick={() => pageFunctions.skipLink(false)}
        onKeyUpCapture={(e) =>
          e.key === "Enter" && pageRefs.main_focus.current.focus()
        }
        onFocus={() => pageFunctions.skipLink()}
        onBlur={() => pageFunctions.skipLink(false)}
        tabIndex={0}
      >
        {language.labels.aria.skip}
      </div>
      <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
      <SideBar
        language={language}
        signout={signout}
        auth={auth}
        communities={communities.length}
        community={community}
        resetMenu={pageFunctions.resetMenu}
        ref={pageRefs.sidebar_ref}
      />
      <main className="main-page" ref={pageRefs.main_focus} tabIndex="0">
        <div
          className="page-section mobile-justify"
          style={{ display: "flex" }}
        >
          <h1 className="heading">
            {language.labels.communities.my_communities.replace(
              /{n}/g,
              communities.length
            )}
          </h1>
        </div>

        {communities.length > 0 && (
          <div className="page-section" style={{ paddingBottom: "2em" }}>
            <section
              id="thumb_wall"
              className="thumb-group-container mobile-justify"
              aria-label={language.labels.aria.grid}
            >
              {communityList}
            </section>
          </div>
        )}
      </main>
    </div>
  );
};

export default CommunityHub;
