import React, { useState, useEffect, useRef } from "react";
import CsvDownloader from "react-csv-downloader";
import { useSelector } from "react-redux";
import { TopBar, SideBar } from "../layout";

import axiosCall from "../../lib/axios";
import { getContext } from "../../lib/signin";
import config from "../../config";
import { Search } from "../widgets";

const Directory = ({ language, signout, pageRefs, pageFunctions }) => {
  ////////////// INITIALIZE //////////////////
  const auth = useSelector((state) => state.authentication.value);

  const search_ref = useRef();

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [level, setLevel] = useState(-1);

  const [downloadData, setDownloadData] = useState([]);
  const [communityMembers, setCommunityMembers] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [name, setName] = useState("");

  let headerRow = [
    { id: "username", displayName: language.labels.members.download.username },
    { id: "name", displayName: language.labels.account.name },
    { id: "email", displayName: language.labels.members.download.email },
    { id: "role", displayName: language.labels.members.download.role },
    { id: "account", displayName: language.labels.account.heading },
  ];

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);

      setLevel(config.levels.indexOf(role));
      if (_community.type === "institution")
        headerRow.push({
          id: "status",
          displayName: language.labels.app.status,
        });
      getMemberList();
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  const getMemberList = async () => {
    let data = { lang: language.lang, type: community.type };
    if (auth?.admin === "Y") data.cid = community.id;
    let result = await axiosCall("community/memberlist", data);
    if (result.success) {
      setDownloadData(result.data.list);
      setCommunityMembers(result.data.members);
      setAllMembers(result.data.members);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getMemberList();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// FILTER SEARCH ///////////
  const searchMembers = () => {
    if (search_ref.current?.value) {
      filterMembers(search_ref.current.value);
    } else {
      filterMembers("");
    }
  };

  const filterMembers = async (val) => {
    let filter = { name: name };
    setName(val);
    filter.name = val.trim();
    let members = [...allMembers];
    console.log(members);
    let people = members.filter(
      (member) =>
        member.username.toLowerCase().includes(filter.name.toLowerCase()) ||
        (member.email_alias &&
          member.email_alias.toLowerCase().includes(filter.name.toLowerCase()))
    );
    setCommunityMembers(people);
  };

  ////////////// RENDER GUI //////////////////
  let community_members = communityMembers.map((data, i) => (
    <div
      className="thumb-group"
      key={i}
      role="figure"
      aria-label={data.username}
      // onKeyDown={(e) => keyAction(e, i)}
      onClick={(e) => {
        // handleEdit(i);
      }}
      tabIndex="0"
    >
      <div className="thumb-group-content">
        <img
          className="profile-image"
          src={config.server.storage.bucket + data.avatar}
          alt={data.username}
        />

        <div
          className="handle"
          style={{ width: "100%", padding: "0 0.5em", minHeight: "1em" }}
        >
          {data.email_alias}
        </div>

        <div className="name" style={{ width: "100%", padding: "0.5em" }}>
          {data.uid === auth.uid ? (
            <>{data.username + " (" + language.labels.app.me + ")"}</>
          ) : (
            <>{data.username}</>
          )}
        </div>

        <div className="handle2" style={{ fontSize: "1em" }}>
          {language.labels.roles[data.role]}
        </div>
      </div>
    </div>
  ));

  return (
    <div className="main" ref={pageRefs._main_}>
      <div
        className="sr-only"
        id="primary_focus"
        tabIndex={0}
        onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
      ></div>
      <div
        ref={pageRefs.skip_link}
        className="link sr-only"
        role="link"
        onClick={() => pageFunctions.skipLink(false)}
        onKeyUpCapture={(e) =>
          e.key === "Enter" && pageRefs.main_focus.current.focus()
        }
        onFocus={() => pageFunctions.skipLink()}
        onBlur={() => pageFunctions.skipLink(false)}
        tabIndex={0}
      >
        {language.labels.aria.skip}
      </div>
      <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
      <SideBar
        language={language}
        signout={signout}
        auth={auth}
        communities={communities.length}
        community={community}
        resetMenu={pageFunctions.resetMenu}
        ref={pageRefs.sidebar_ref}
      />
      <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
        {level < 0 ? (
          <> </>
        ) : (
          <>
            <div className="page-section" style={{ display: "flex" }}>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <div
                  role="img"
                  aria-label={language.labels.aria.logo.replace(
                    /{org}/g,
                    community.title
                  )}
                  className="menu-crest"
                  style={{
                    height: "3em",
                    width: "3em",
                    backgroundImage: `url("${
                      config.server.storage.bucket + community.crest
                    }")`,
                  }}
                ></div>
                <div style={{ width: "0.5em" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "calc(100% - 2.5em)",
                  }}
                >
                  <h1
                    className="heading"
                    style={{ width: "100%", padding: "0" }}
                  >
                    {language.labels.communities.directory}
                  </h1>
                  <div className="handle2" style={{ width: "100%" }}>
                    {community.title}
                  </div>
                </div>
              </div>
            </div>

            <div className="page-section" style={{ maxWidth: "66rem" }}>
              {level > 2 && (
                <CsvDownloader
                  filename={language.labels.members.download.file.replace(
                    /{org}/g,
                    community.uname
                  )}
                  columns={headerRow}
                  datas={downloadData}
                >
                  <button
                    className="button"
                    style={{ marginTop: "1em", fontSize: "0.9em" }}
                  >
                    {language.labels.members.download.list}
                  </button>
                </CsvDownloader>
              )}

              <div
                className="text-label"
                style={{ marginTop: `${level > 2 ? "2em" : "0"}` }}
              >
                {language.labels.members.filter}
              </div>
              <Search
                search_ref={search_ref}
                onChange={searchMembers}
                placeholder={language.labels.members.filter_placeholder}
              />
            </div>

            <div className="page-section">
              {name.trim() === "" ? (
                <div>
                  {language.labels.members.total_count.replace(
                    /{n}/g,
                    communityMembers.length
                  )}
                </div>
              ) : (
                <div>
                  {language.labels.communities.staff_filtered.replace(
                    /{n}/g,
                    communityMembers.length
                  )}
                </div>
              )}
            </div>

            <div className="page-section">
              <section
                id="thumb_wall"
                className="thumb-group-container"
                aria-label={language.labels.navigation.community_managers}
              >
                {communityMembers.length > 0 ? (
                  community_members
                ) : (
                  <i>{language.labels.communities.staff_filtered_none}</i>
                )}
              </section>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Directory;
