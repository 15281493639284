import { React, useState, useRef, useEffect } from "react";

import validator from "../../lib/validation";
import { naturalDate } from "../../lib/date";
import Modal from "../modal/Modal";
import config from "../../config";
import axiosCall from "../../lib/axios";

const Emails = ({ language, signout, phone }) => {
  const code_ref = useRef();
  const new_ref = useRef();

  const [emailStr, setEmailStr] = useState("[]");
  const [emailArr, setEmailArr] = useState([]);
  const [code, setCode] = useState("");
  const [codeModal, setCodeModal] = useState(false);
  const [recoveryModal, setRecoveryModal] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState({});
  const [emailStatus, setEmailStatus] = useState("");
  const [verifyStatus, setVerifyStatus] = useState("");
  const [errStatus, setErrStatus] = useState("");

  useEffect(() => {
    if (localStorage.getItem("emails"))
      setEmailStr(localStorage.getItem("emails"));
  }, []);

  useEffect(() => {
    console.log("fix mail", JSON.parse(emailStr));
    setEmailArr(JSON.parse(emailStr));
  }, [emailStr]);

  ////////////// EMAILS //////////////
  const check_address = async (email_address) => {
    //check that 'new' mail isn't already in list
    let emails = emailArr;
    for (var i = 0; i < emails.length; i++) {
      if (emails[i].email === email_address) {
        return true;
      } else if (i === emails.length - 1) {
        return false;
      }
    }
  };

  const verify_success = async () => {
    let emails = emailArr;
    let _emails = [];
    for (var i = 0; i < emails.length; i++) {
      if (emails[i].email === verifyEmail.email) {
        _emails.push({
          email: verifyEmail.email,
          created: verifyEmail.created,
          active: true,
        });
      } else {
        _emails.push(emails[i]);
      }
    }
    setVerifyEmail({});
    setCodeModal(false);
    reset_emails(_emails);
  };

  const reset_emails = (_emails) => {
    localStorage.setItem("emails", JSON.stringify(_emails));
    setEmailStr(JSON.stringify(_emails));
  };

  const verify_mail = async (email) => {
    setVerifyEmail(email);
    setCodeModal(true);
  };

  const verify = async () => {
    let result = await axiosCall("user/email/verify", {
      email: verifyEmail.email || "",
      code,
    });
    if (result.success) {
      if (result.status === 200) {
        verify_success();
      } else {
        setVerifyStatus(language.labels.error.code_invalid);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      verify();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const cancel = () => {
    setCodeModal(false);
    setRecoveryModal(false);
    setCode("");
    setEmailStatus("");
    setVerifyStatus("");
    setVerifyEmail({});
  };

  const submit_email = async () => {
    if (new_ref.current) {
      const address = new_ref.current.value.trim().toLowerCase();

      var email_chk = validator.emailValidator(address);
      if (!email_chk) {
        setEmailStatus("email_invalid");
      } else {
        setEmailStatus("");
        let result = await axiosCall("user/email/add", {
          email: address,
          language: language.lang,
        });
        if (result.success) {
          if (result.status === 200) {
            const existing = await check_address(address);
            if (!existing) {
              const _created = new Date();
              var newEmails = emailArr;
              newEmails.push({
                active: false,
                email: address,
                created: _created.toISOString(),
              });
              reset_emails(newEmails);
            }
            setRecoveryModal(false);
          } else {
            setEmailStatus(result.data.message);
          }
        } else if (result.refresh) {
          //token has been refreshed, try again
          submit_email();
        } else {
          //refresh token expired or unknown error
          signout();
        }
      }
    }
  };

  const remove_email = async (emailToDelete) => {
    setErrStatus("");
    let emails = emailArr;
    let result = await axiosCall("user/email/remove", {
      email: emailToDelete,
    });
    if (result.success) {
      var newEmails = emails.filter(function (removed) {
        return removed.email !== emailToDelete;
      });
      reset_emails(newEmails);
    } else if (result.refresh) {
      //token has been refreshed, try again
      remove_email(emailToDelete);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ///////////////// RENDER GUI /////////////////
  const emailList = emailArr.map((email, index) => (
    <div className="email" key={index}>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>{email.email}</div>
        {email.active && (phone || emailArr.length > 1) ? (
          <button
            className="glyphs hover"
            style={{ fontSize: "1.2rem" }}
            onClick={() => {
              remove_email(email.email);
            }}
          >
            T
          </button>
        ) : (
          (phone || emailArr.length > 1) && (
            <div style={{ fontSize: "0.9em" }}>
              {language.labels.app.unverified}
            </div>
          )
        )}
      </div>
      {!email.active && (
        <div
          style={{
            display: "flex",
            marginTop: "0.5em",
            gap: "0.5em",
            fontSize: "0.9em",
          }}
        >
          <button onClick={() => verify_mail(email)} className="link">
            {language.labels.app.verify}
          </button>
          <div>|</div>
          <button
            className="link"
            onClick={() => {
              remove_email(email.email);
            }}
          >
            {language.labels.app.remove.toLowerCase()}
          </button>
        </div>
      )}
    </div>
  ));

  return (
    <>
      <div
        className="mobile-margin mobile-center"
        style={{ display: "flex", flexDirection: "column", maxWidth: "44rem" }}
      >
        {emailArr.length > 0 ? (
          <div className="email-container">{emailList}</div>
        ) : (
          <div style={{ marginTop: "0.5em" }}>
            {language.labels.account.email_none}
          </div>
        )}
        {errStatus && (
          <div className="errtext">{language.labels.error[errStatus]}</div>
        )}

        {/* email limit max 3 */}
        {emailArr.length < 3 && (
          <div>
            <button
              className="link"
              style={{ marginTop: "1em" }}
              onClick={() => setRecoveryModal(true)}
            >
              {language.labels.account.email_add}
            </button>
          </div>
        )}
      </div>

      {/* verify email */}
      <Modal
        show={codeModal}
        language={language}
        title={language.labels.account.email_verify}
        className={"modal-dynamic"}
        onOk={verify}
        onClose={cancel}
      >
        <div className="quote-text" style={{ marginTop: "1rem" }}>
          {verifyEmail.email}
        </div>
        <div style={{ lineHeight: "1.2rem", marginTop: "1.5rem" }}>
          {language.labels.authentication.code_mail_prompt_date.replace(
            /{date}/g,
            naturalDate(
              verifyEmail.created,
              language.locale,
              language.labels.date
            )
          )}
        </div>
        <input
          ref={code_ref}
          style={{ marginTop: "0.5rem", width: "100%" }}
          placeholder={language.labels.authentication.code}
          aria-label={language.labels.authentication.code}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          onKeyUpCapture={(e) =>
            (e.key === "Enter" || e.key === " ") && verify()
          }
          tabIndex="0"
          autoFocus
        />

        {verifyStatus && (
          <div
            style={{ fontWeight: 600, marginBottom: ".5rem" }}
            className="errtext"
            role="alert"
          >
            {verifyStatus}
          </div>
        )}

        <div
          style={{
            marginTop: "1em",
            fontSize: "0.8em",
            fontWeight: 300,
          }}
        >
          {language.labels.authentication.email_advice}
        </div>
      </Modal>

      {/* add email */}
      <Modal
        show={recoveryModal}
        language={language}
        title={language.labels.account.email_add}
        className={"modal-dynamic"}
        onOk={submit_email}
        onClose={cancel}
      >
        <input
          ref={new_ref}
          className="natural-edit"
          style={{ maxWidth: "22rem" }}
          onKeyUpCapture={(e) => e.key === "Enter" && submit_email()}
          placeholder={language.labels.account.email_enter}
          type={"email"}
          maxLength={config.string.title}
        />

        <div style={{ marginTop: "0.25em", fontWeight: 300 }}>
          {language.labels.authentication.code_mail_verify}
        </div>

        {emailStatus && (
          <div className="errtext mobile-center" style={{ marginTop: "0.5em" }}>
            {language.labels.error[emailStatus]}
          </div>
        )}
      </Modal>
    </>
  );
};

export default Emails;
