import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import config from "../../../../config";
import refreshToken from "../../../../lib/refreshToken";
import Modal from "../../../modal/Modal";
import { ImageEditor } from "../../../widgets";
import Pexels from "../../../../media/pexels.png";

const Image = ({
  next,
  animation,
  language,
  createBlock,
  dlp,
  setDlp,
  props,
}) => {
  //////////// INITIALIZATION ///////////
  //image editor file/URL setters
  const [file, setFile] = useState("");
  const [err, setErr] = useState("");
  const [imageSearch, setImageSearch] = useState("");
  const [imageAI, setImageAI] = useState(false);
  const [aiModal, setAiModal] = useState(false);

  //refs
  const canvas_ref = useRef();
  const ai_ref = useRef();

  // AbortController
  const abortRef = useRef(new AbortController());

  useEffect(() => {
    if (props.bitesArr) {
      setImageSearch(encodeURIComponent(props.bitesArr[props.currBite]));
    } else {
      setImageSearch(encodeURIComponent(props.title));
    }

    if (animation && animation.step === 2) {
      if (animation.direction === 1) {
        document.getElementById("animateImage").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("animateImage").style.animationName =
          "slideout";
        document.getElementById("animateImage").style.animationDirection =
          "reverse";
      }
    }
  }, []);

  //////////// USER INTERACTIONS ///////////
  const submitForm = (e) => {
    e.preventDefault();
    setErr("");

    if (imageAI) {
      // Create a canvas to draw the resized image
      const ai_canvas = document.createElement("canvas");
      const ctx = ai_canvas.getContext("2d");
      ai_canvas.width = 120;
      ai_canvas.height = 120;
      ctx.drawImage(ai_ref.current, 0, 0, 120, 120);
      const resizedBase64 = ai_canvas.toDataURL();
      createBlock(resizedBase64);
    } else {
      //use canvas
      let canvas = canvas_ref.current;
      if (canvas && file) {
        var data_uri = canvas.toDataURL();
        createBlock(data_uri);
      } else {
        setErr(`${language.labels.bites.image_none}`);
      }
    }
  };

  const back = () => {
    setDlp("");
    document.getElementById("animateImage").style.animationName = "slidein";
    document.getElementById("animateImage").style.animationDirection =
      "reverse";
    next({ step: 0, direction: -1 });
  };

  ////////////// AI THUMB GENERATION //////////////////
  const abortRequest = () => {
    abortRef.current?.abort();
    abortRef.current = new AbortController();
  };

  const aiThumb = async () => {
    // init
    setErr("");
    setAiModal(true);
    document.getElementById("loader").style.display = "inline";

    try {
      //get text for image generation
      let text = "";
      if (props.bitesArr) {
        text = props.bitesArr[props.currBite];
      } else {
        text = props.title;
      }
      if (imageAI) text = text + ". " + props.description;

      //call api
      let result = await axios({
        method: "post",
        url: `${config.server.api}/block/ai/image/hub/${localStorage.getItem(
          "client_id"
        )}`,
        withCredentials: true,
        credentials: "include",
        data: { cid: props.cid, text },
        signal: abortRef.current.signal,
      });
      document.getElementById("loader").style.display = "none";

      //handle result
      if (result.status === 202) {
        let r = await refreshToken();
        if (r) {
          //token has been refreshed, try again
          aiThumb();
        } else {
          //refresh token expired
          throw new Error();
        }
      } else {
        setAiModal(false);
        setImageAI(true);
        ai_ref.current.src = `data:image/png;base64,${result.data}`;
      }
    } catch (err) {
      document.getElementById("loader").style.display = "none";
      setAiModal(false);
      if (err.name !== "CanceledError") setErr(language.labels.error.unknown);
    }
  };

  const manualThumb = () => {
    setErr("");
    setImageAI(false);
    ai_ref.current.src = "";
  };

  ////////////// RENDER GUI //////////////////
  const extras = props.extraSections.map((extra, index) => (
    <div style={{ marginTop: "0.5em", fontSize: "0.9em" }} key={index}>
      <div style={{ fontWeight: "600" }}>{extra.heading}</div>
      <div style={{ fontStyle: "italic" }}>{extra.description}</div>
    </div>
  ));

  return (
    <>
      <div style={{ position: "relative", maxWidth: "44rem" }}>
        <form
          id="animateImage"
          className="block-step page-section"
          onSubmit={(e) => submitForm(e)}
        >
          {props.title && (
            <h2
              className="heading2"
              style={{
                fontWeight: "500",
                fontSize: "1.1em",
                marginBottom: "0.25em",
              }}
            >
              {props.title}
            </h2>
          )}

          <div style={{ fontSize: "0.9em", fontStyle: "italic" }}>
            {props.description}
          </div>

          {extras}

          <h2
            className="heading2"
            style={{ fontWeight: "500", marginTop: "1em" }}
          >
            {language.labels.bites.image}
          </h2>

          {!imageAI && (
            <ImageEditor
              setFile={setFile}
              language={language}
              file={file}
              dim={400}
              ref={canvas_ref}
            />
          )}

          {err && (
            <div
              style={{ fontWeight: 600, marginTop: "0.5rem" }}
              className="errtext"
              role="alert"
            >
              {err}
            </div>
          )}

          <img
            ref={ai_ref}
            width="120px"
            height="120px"
            style={{
              marginTop: "0.5em",
              display: `${imageAI ? "inline" : "none"}`,
            }}
          />

          <div
            className="link"
            style={{ marginTop: "0.5em" }}
            tabIndex={0}
            onClick={aiThumb}
            onKeyUp={(e) => (e.key === "Enter" || e.key === " ") && aiThumb()}
          >
            {imageAI
              ? language.labels.ai.suggestions.thumb_redo
              : language.labels.ai.suggestions.thumb}
          </div>

          {imageAI ? (
            <div
              className="link"
              style={{ marginTop: "0.5em" }}
              tabIndex={0}
              onClick={manualThumb}
              onKeyUp={(e) =>
                (e.key === "Enter" || e.key === " ") && manualThumb()
              }
            >
              {language.labels.ai.suggestions.thumb_manual}
            </div>
          ) : (
            <>
              <div
                style={{
                  margin: "1em 0 0.25em 0",
                  fontSize: "0.9em",
                  fontStyle: "italic",
                }}
                dangerouslySetInnerHTML={{
                  __html: language.labels.bites.pexels.replace(
                    /{search}/,
                    imageSearch
                  ),
                }}
              ></div>
              <a
                href={"https://www.pexels.com/search/" + imageSearch}
                target="_blank"
              >
                <img
                  src={Pexels}
                  style={{ marginTop: "0.25em", width: "80px" }}
                />
              </a>
            </>
          )}

          {dlp && (
            <div
              style={{
                fontWeight: 600,
                marginTop: "1.5rem",
                marginBottom: "-1rem",
              }}
              className="errtext"
              role="alert"
            >
              {language.labels.error.dlp_bite[dlp]}
            </div>
          )}

          <div style={{ display: "flex", marginTop: "2em" }}>
            <div
              role="link"
              className="button-secondary"
              onClick={back}
              onKeyDown={(e) => e.key === "Enter" && back()}
              tabIndex={0}
            >
              {language.labels.app.action_back}
            </div>
            <div style={{ width: "0.25em" }}></div>
            <button
              className="button"
              style={{ borderWidth: "2px" }}
              type="submit"
            >
              {language.labels.bites.create}
            </button>
          </div>
        </form>
      </div>

      {/* AI Modal */}
      <Modal className="modal-dynamic" show={aiModal}>
        <div style={{ margin: "1em 0" }}>
          <h2 className="heading">{language.labels.loader.wait}</h2>
          <div style={{ fontSize: "1.1em", padding: "0.5rem" }}>
            {language.labels.ai.moment}
          </div>
          <div
            className="dotdotdot"
            style={{ fontSize: "1.1em", padding: "0.5rem" }}
          >
            {language.labels.ai.image}
          </div>
          <div style={{ display: "flex", marginTop: "1em" }}>
            <button className="button-off" onClick={abortRequest}>
              {language.labels.ai.cancel}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Image;
