import React, { useState, useEffect, useRef } from "react";

import config from "../../../config";

import axiosCall from "../../../lib/axios";
import { naturalDate } from "../../../lib/date";

const StreamViewers = ({ language, pageHeight, signout, params }) => {
  console.log(params);

  //////////// INITIALIZATION ///////////
  const pageRef = useRef();
  const bottomRef = useRef();
  const loadingRef = useRef();
  const hasMoreRef = useRef();

  const [viewerList, setViewerList] = useState([]);

  //fix per_page to largish value as resizing problematic
  const per_page = 20;

  useEffect(() => {
    //ini refs
    pageRef.current = 0;
    loadingRef.current = false;
    hasMoreRef.current = true;

    if (params.viewers.count > 0) {
      setViewerList(params.viewers);
    } else {
      getViewers();
    }

    //listen to scroll
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //////////// SCROLLING ///////////
  const handleScroll = () => {
    //check if within 200px of top
    if (bottomRef?.current.getBoundingClientRect().top < pageHeight + 200) {
      getViewers(pageRef.current);
    }
  };

  //////////// DATA ///////////
  const getViewers = async () => {
    console.log("get the viewers");
    let result = await axiosCall("live/viewerlist", {
      streamId: params.streamId,
    });
    console.log(result);
    // if (result.success) {
    //   setLive(result.data);
    // } else if (result.refresh) {
    //   //token has been refreshed, try again
    //   getCurrent();
    // } else {
    //   //refresh token expired or unknown error
    //   signout();
    // }
  };

  //////////// RENDER GUI ///////////
  const mapped = viewerList.map((viewer, index) => {
    return (
      <div role="figure" className="card-long hover" key={index} tabIndex={0}>
        {/* publisher */}
        {/* <div>
          <div
            style={{
              backgroundImage: `url(${config.server.storage.bucket}${stream.pub_avatar})`,
              flexShrink: "0",
              borderRadius: "50%",
            }}
            className="thumb-block auto-margin-narrow"
          ></div>
          <div
            style={{
              fontWeight: "500",
              fontSize: "0.9em",
              textAlign: "center",
              marginTop: "2px",
            }}
          >
            {stream.pub_name}
          </div>
        </div> */}

        {/* details */}
        <div className="block-info">
          {/* <div
            className="clamped one"
            style={{ fontWeight: "500", fontSize: "1.1em" }}
          >
            {stream.streamName}
          </div> */}

          {/* <div style={{ fontSize: "0.8em", margin: "0.2em 0" }}>
            {language.labels.live.started.replace(
              /{date}/g,
              naturalDate(stream.started, language.locale, language.labels.date)
            )}
          </div> */}

          {/* <div
            className="contrast clamped one"
            style={{ fontStyle: "italic", marginTop: "0.5em" }}
          >
            {stream.pathwayTitle}
          </div> */}

          {/* status */}
          {/* <div
            className={
              stream.ended
                ? "middle"
                : stream.stopping
                ? "amber"
                : stream.timestamp
                ? "alert"
                : "amber"
            }
            style={{ fontWeight: 500, margin: "0.5em 0" }}
          >
            {stream.ended
              ? language.labels.live.ended_time.replace(
                  /{time}/g,
                  naturalDate(
                    stream.ended,
                    language.locale,
                    language.labels.date
                  )
                )
              : stream.stopping
              ? language.labels.live.streaming.ending
              : stream.timestamp
              ? language.labels.live.streaming.now
              : language.labels.live.streaming.pending}
          </div> */}
        </div>
      </div>
    );
  });

  return (
    <div style={{ maxWidth: "66rem", paddingBottom: "1em" }}>
      {/* back button */}
      <div
        style={{ display: "flex", alignItems: "center", gap: "2px" }}
        onClick={() => params.setShowViewers(false)}
      >
        <button
          className="glyphs link-button"
          aria-label={language.labels.live.back}
          onClick={() => params.setShowViewers(false)}
          style={{ fontSize: "0.75em" }}
        >
          &lt;
        </button>
        <div className="link" aria-hidden={true}>
          {language.labels.live.back}
        </div>
      </div>
      <>{mapped}</>
    </div>
  );
};

export default StreamViewers;
