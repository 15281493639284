import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

import axiosCall from "../../../lib/axios";
import config from "../../../config";
import { trueTime, duration } from "../../../lib/date";

const LivePlay = ({ language, signout }) => {
  ////////////// INITIALIZE //////////////////
  const navigate = useNavigate();
  const { state } = useLocation();

  const auth = useSelector((state) => state.authentication.value);
  const readOnly = auth.account_type === "free";

  const [playing, setPlaying] = useState(false);
  const [started, setStarted] = useState(false);
  const [timestamp, setTimestamp] = useState(null);
  const [dataChannel, setDataChannel] = useState(false);
  const [expert, setExpert] = useState(false);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [websocketError, setWebsocketError] = useState(false);
  const [activeCount, setActiveCount] = useState(0);

  const [messages, setMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [audioOff, setAudioOff] = useState(false);
  const [micOff, setMicOff] = useState(false);
  const [camOff, setCamOff] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(
    language.labels.live.streaming.connecting
  );
  const [statusClass, setStatusClass] = useState("amber flashing");

  const videoRef = useRef();
  const avatarRef = useRef();
  const publisherRef = useRef();
  const camOffRef = useRef();
  const timerRef = useRef();
  const timeNow = useRef();
  const durationRef = useRef(0);
  const endedRef = useRef(false);

  const msgArr = useRef([]);
  const msgBox = useRef();
  const msgRef = useRef();
  const userRef = useRef({});
  const webRTCAdaptor = useRef(null);
  var playingStreamId = useRef(null);

  useEffect(() => {
    if (!state?.streamId) {
      // not kosher
      navigate("/livestreams");
    } else if (!webRTCAdaptor.current) {
      // initialize websocket
      initWebSocket();
    }
  }, []);

  useEffect(() => {
    //start playing
    if (websocketConnected && !readOnly) initPlay();
  }, [websocketConnected]);

  useEffect(() => {
    //kick off timer
    if (timestamp) timer();
  }, [timestamp]);

  useEffect(() => {
    //fix display
    if (camOffRef.current) {
      if (camOff) {
        let height = videoRef.current.parentNode.offsetHeight;
        videoRef.current.style.minHeight = height + "px";
        videoRef.current.parentNode.style.height = height + "px";
        camOffRef.current.style.marginTop = height / 2 - 90 + "px";
        camOffRef.current.style.opacity = 1;
        camOffRef.current.style.backgroundImage = `url(${
          config.server.storage.bucket + avatarRef.current
        })`;
      } else {
        videoRef.current.parentNode.style.height = "unset";
        videoRef.current.style.minHeight = "0px";
        camOffRef.current.style.opacity = 0;
        camOffRef.current.style.backgroundImage = "unset";
      }
    }
  }, [camOff]);

  useEffect(() => {
    if (!readOnly) {
      console.log(videoRef.current.muted);
      videoRef.current.muted = audioOff;
    }
  }, [audioOff]);

  ////////////// WEBSOCKET //////////////////
  const initWebSocket = () => {
    webRTCAdaptor.current = new WebRTCAdaptor({
      websocket_url: config.server.live.websocket_url,
      mediaConstraints: {
        video: false,
        audio: false,
      },
      peerconnection_config: {
        iceServers: [{ urls: config.server.live.ice }],
      },
      sdp_constraints: {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false,
      },
      remoteVideoId: "remoteVideo",
      // bandwidth: 900, // default is 900 kbps, string can be 'unlimited'
      // dataChannelObserver: true, // enable or disable data channel
      callback: (info, obj) => {
        switch (info) {
          case "pong":
            break;
          case "bitrateMeasurement":
            break;
          case "initialized":
            console.log(info);
            setWebsocketConnected(true);
            break;
          case "play_started":
            console.log(info);
            setPlaying(true);
            setStatus(language.labels.live.streaming.now);
            setStatusClass("alert");
            setError(null);
            break;
          case "data_channel_opened":
            console.log(info);
            setDataChannel(true);
            break;
          case "data_received":
            try {
              let msg = JSON.parse(obj.data);
              let event = msg.type || "";
              switch (event) {
                case "chat":
                  console.log(info);
                  popMessage(msg.chat);
                  break;
                case "camOff":
                  setCamOff(true);
                  break;
                case "camOn":
                  setCamOff(false);
                  break;
                case "micOff":
                  setMicOff(true);
                  break;
                case "micOn":
                  setMicOff(false);
                  break;
                case "count":
                  if (Number.isInteger(msg.count)) setActiveCount(msg.count);
                  break;
              }
            } catch (e) {}
            break;
          case "data_channel_closed":
            console.log(info);
            setDataChannel(false);
            break;
          case "play_finished":
            console.log(info);
            break;
          default:
            console.log(info);
            console.log(obj);
            break;
        }
      },
      callbackError: function (error, message) {
        console.log("ws callback error ", error);
        console.log(message);
        if (error === "no_stream_exist") {
          //set error broadcast ended;
          webRTCAdaptor.current.closeStream(playingStreamId.current);
          endedRef.current = true;
          setPlaying(false);
          setStarted(false);
          setStatus(language.labels.live.streaming.ended);
          setStatusClass("inert");
        } else {
          onSocketFail(error);
        }
      },
    });
  };

  const onSocketFail = (error) => {
    console.log("websocket error ", error);
    console.log("websocket connected ", websocketConnected);
    if (!websocketConnected) {
      webRTCAdaptor.current = null;
      setWebsocketError(true);
    }
  };

  ////////////// PLAY //////////////////
  const initPlay = async () => {
    // retrieve token and stream id
    if (state?.streamId) {
      setError("");
      let result = await axiosCall("live/totp", {
        streamId: state.streamId,
        type: "play",
      });
      if (result.success) {
        if (result.status === 200) {
          setStarted(true);
          handlePlay(result.data);
        } else {
          switch (result.data.message) {
            case "OTHER DEVICE":
              setStatus(language.labels.live.already.viewing);
              setStatusClass("alert");
              break;
            case "NOT STARTED":
              setStatus(language.labels.live.streaming.off);
              setStatusClass("inert");
              break;
            default:
              setStatus(language.labels.live.streaming.ended);
              setStatusClass("inert");
              break;
          }
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        initPlay();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  const handlePlay = async (obj) => {
    console.log("handle Play");
    try {
      userRef.current = { username: obj.username, avatar: obj.avatar };
      durationRef.current = obj.duration;
      playingStreamId.current = obj.streamId;
      webRTCAdaptor.current.play(
        obj.streamId,
        "",
        "",
        [],
        obj.subscriberId,
        obj.subscriberCode
      );
      avatarRef.current = obj.pub_avatar;
      publisherRef.current = obj.pub_fullname || obj.pub_name;
      if (obj.expert) setExpert(obj.expert);
      if (obj.camOff) setCamOff(obj.camOff);
      if (obj.micOff) setMicOff(obj.micOff);
      timeNow.current = parseInt(obj.timeNow);
      setTimestamp(obj.timestamp);
      setActiveCount(obj.viewers + 1);
    } catch (err) {
      console.log("handle play error ", err);
      setError(language.labels.error.unknown);
      setStatus(language.labels.live.streaming.not_connected);
      setStatusClass("inert");
    }
  };

  const handleStopPlaying = () => {
    webRTCAdaptor.current.stop(playingStreamId.current);
    webRTCAdaptor.current.closeStream(playingStreamId.current);
    webRTCAdaptor.current = null;
    window.location.href = "/livestreams";
  };

  ////////////// TIMER //////////////////
  const timer = () => {
    if (window.location.pathname === "/playstream") {
      let milliseconds = timeNow.current - parseInt(timestamp);
      const elapsed = (durationRef.current + milliseconds) / 1000;
      timerRef.current.innerText = duration(Math.max(elapsed, 0));
      if (timestamp) {
        setTimeout(() => {
          timeNow.current = timeNow.current + 1000;
          timer();
        }, 1000);
      }
    }
  };

  ////////////// CHAT //////////////////
  const sendMessage = () => {
    let msg = msgRef.current.value.trim();
    if (msg) postMessage(msg);
  };

  const postMessage = (message) => {
    if (playingStreamId.current) {
      let chat = {
        message,
        username: userRef?.current.username,
        avatar: userRef?.current.avatar,
      };
      if (expert) chat.expert = expert;
      webRTCAdaptor.current.sendData(
        playingStreamId.current,
        JSON.stringify({ type: "chat", chat })
      );
      popMessage(chat);
      msgRef.current.value = "";
    }
  };

  const popMessage = (message) => {
    //put message at start of array
    msgArr.current.unshift(message);
    setMessages(msgArr.current);
    setMessageCount(msgArr.current.length);
  };

  ////////////// RENDER GUI //////////////////
  const chat_map = messages.map((message, index) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          marginTop: "0.5em",
          gap: "0.5em",
        }}
      >
        {message.avatar && (
          <div
            className="avatar medium"
            style={{
              flexShrink: 0,
              backgroundImage: `url("${
                config.server.storage.bucket + message.avatar
              }")`,
            }}
          ></div>
        )}
        {message.glyph && (
          <div
            className="avatar medium glyphs alert"
            style={{
              fontSize: "1.2em",
              flexShrink: 0,
              textAlign: "center",
            }}
          >
            {message.glyph}
          </div>
        )}
        <div style={{ fontSize: "0.9em" }}>
          <div style={{ display: "flex", gap: "0.5em" }}>
            {message.username && (
              <div style={{ fontWeight: 500 }}>{message.username}</div>
            )}
            {message.host && <div>{language.labels.live.role_host}</div>}
            {message.expert && <div>{language.labels.live.role_expert}</div>}
          </div>
          <div
            className="quote-text"
            style={{ marginTop: `${message.glyph ? "0" : "0.2em"}` }}
          >
            {message.message}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div
      className="page-section"
      style={{
        paddingBottom: "6em",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="live-container">
        {/* HEADINGS */}
        <h1 className="heading" style={{ textAlign: "center" }}>
          {language.labels.live.learning_stream}
        </h1>

        {readOnly ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "1em 0",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "1em", textAlign: "center" }}>
              {language.labels.live.subscription_only}
            </div>
            <button className="button" onClick={() => navigate("/livestreams")}>
              {language.labels.app.ok}
            </button>
          </div>
        ) : (
          <>
            {websocketConnected ? (
              <>
                {/* stream name */}
                <div
                  className="contrast"
                  style={{
                    marginTop: "0.5em",
                    fontSize: "1.1em",
                    textAlign: "center",
                  }}
                >
                  {state?.streamName}
                </div>

                {/* live status */}
                <div
                  className={statusClass}
                  style={{
                    fontWeight: "bold",
                    padding: "1em 0",
                    textAlign: "center",
                  }}
                >
                  {status}
                </div>

                {/* viewers and start time */}
                {playing && (
                  <div
                    style={{
                      margin: "-0.5em 0 1em",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      {activeCount === 1
                        ? language.labels.live.viewers.singular
                        : language.labels.live.viewers.plural.replace(
                            /{n}/g,
                            activeCount
                          )}
                    </div>

                    <div style={{ marginTop: "0.5em" }}>
                      {language.labels.live.started.replace(
                        /{date}/g,
                        trueTime(state.streamStarted)
                      )}
                    </div>
                  </div>
                )}

                {error && (
                  <div
                    className="errtext"
                    role="alert"
                    style={{ margin: "0 0 2em", textAlign: "center" }}
                  >
                    {error}
                  </div>
                )}

                {/* quit button */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1em",
                  }}
                >
                  <button className="button" onClick={handleStopPlaying}>
                    {endedRef.current || !playing
                      ? language.labels.app.action_close
                      : started
                      ? language.labels.live.quit
                      : language.labels.app.cancel}
                  </button>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className={websocketError ? "alert" : "flashing"}
                  style={{ margin: "1em 0 2em", textAlign: "center" }}
                >
                  {websocketError
                    ? language.labels.error.unknown_later
                    : language.labels.live.connecting}
                </div>
                {websocketError && (
                  <button
                    className="button"
                    onClick={() => navigate("/livestreams")}
                  >
                    {language.labels.app.ok}
                  </button>
                )}
              </div>
            )}

            {/********* STAGE *********/}
            <div
              className="live-stage"
              style={{
                opacity: `${playing ? 1 : 0}`,
                maxHeight: `${started ? "unset" : "0px"}`,
              }}
            >
              {/* video */}
              <div className="live-video">
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    width: "100%",
                  }}
                >
                  <div ref={camOffRef} className="thumb-paused"></div>
                  {camOff && (
                    <div
                      className="font-white"
                      style={{ textAlign: "center", marginTop: "0.2em" }}
                    >
                      {publisherRef.current}
                    </div>
                  )}
                </div>
                <video
                  id="remoteVideo"
                  ref={videoRef}
                  controls={false}
                  autoPlay={true}
                  muted={false}
                  playsInline={true}
                  style={{
                    display: "block",
                    opacity: `${camOff ? 0 : 1}`,
                    transition: "min-height 500ms ease-in-out",
                  }}
                ></video>
                {/* video timer */}
                <div
                  className="live-heading"
                  style={{
                    justifyContent: "center",
                    opacity: `${timestamp ? 1 : 0}`,
                  }}
                >
                  <div className="timer" ref={timerRef}></div>
                </div>
                {/* presenter mute */}
                {micOff && (
                  <div
                    className="live-heading"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div className="muted">
                      <div className="glyphs" style={{ fontSize: "1.1em" }}>
                        "
                      </div>
                      <div style={{ fontSize: "0.9em" }}>
                        {language.labels.live.host_muted}
                      </div>
                    </div>
                  </div>
                )}
                {/* video control buttons */}
                {playing && (
                  <div className="live-control">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1em",
                      }}
                    >
                      {/* audio */}
                      <button
                        className={
                          audioOff
                            ? "glyphs live-button off"
                            : "glyphs live-button on"
                        }
                        onClick={() => setAudioOff(!audioOff)}
                      >
                        {audioOff ? "`" : "'"}
                      </button>

                      {/* stop */}
                      <button
                        className="glyphs live-button stop"
                        onClick={handleStopPlaying}
                      >
                        ]
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* chat box */}
              <div className="live-panel">
                <div
                  className="live-panel-box"
                  ref={msgBox}
                  style={{
                    position: "absolute",
                    display: "flex",
                    padding: "0.5em",
                    flexDirection: "column-reverse",
                    gap: "0.5em",
                    overflowY: "auto",
                    bottom: "50px",
                    height: "Calc(100% - 50px)",
                  }}
                >
                  {chat_map}
                </div>
                {/* message input */}
                <div className="live-control message">
                  {/* panel control buttons */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 0.5em",
                      gap: "0.25em",
                      width: "100%",
                    }}
                  >
                    <input
                      type="text"
                      ref={msgRef}
                      style={{
                        opacity: `${dataChannel ? 1 : 0.6}`,
                        width: "Calc(100% - 32px)",
                      }}
                      disabled={!playing}
                      placeholder={language.labels.live.enter_message}
                      onKeyUpCapture={(e) => e.key === "Enter" && sendMessage()}
                    ></input>
                    {/* send */}
                    <button
                      aria-label={language.labels.aria.send_message}
                      disabled={!playing}
                      className="glyphs live-button on"
                      style={{ opacity: `${dataChannel ? 1 : 0.3}` }}
                      onClick={() => sendMessage()}
                    >
                      P
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/********* FOCUS  *********/}
            <div
              className="card-long"
              style={{
                display: "block",
                textAlign: "center",
                paddingBottom: "2em",
                opacity: `${playing ? 1 : 0}`,
              }}
            >
              <h2
                className="heading2"
                style={{ fontWeight: 300, fontSize: "1.2em" }}
              >
                {language.labels.live.focus}
              </h2>
              {/* pathway */}
              <div
                style={{
                  display: "flex",
                  gap: "0.5em",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "1.5em 0 1em",
                }}
              >
                <div role="img" className="thumb-glyph tiny auto-margin-narrow">
                  p
                </div>
                <div style={{ fontWeight: 500 }}>{state?.pathway}</div>
              </div>

              {/* bites */}
              {state?.bitesArr.map((bite, index) => (
                <div
                  style={{
                    display: "flex",
                    gap: "0.5em",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <div
                    role="img"
                    className="thumb-block border tiny auto-margin-narrow"
                    style={{
                      backgroundImage: `url("${
                        config.server.storage.bucket + bite.thumb
                      }")`,
                    }}
                  ></div>
                  <div className="quote-text" style={{ fontSize: "0.9em" }}>
                    {bite.title}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LivePlay;
