import { React, useState, useEffect } from "react";

const SuccessPage = ({ language, animation, email, phone, registration }) => {
  const [message, setMessage] = useState("");

  console.log(email);
  console.log(phone);

  useEffect(() => {
    if (registration === "email") {
      setMessage(
        language.labels.authentication.password_success_mail.replace(
          /{email}/g,
          email
        )
      );
    } else if (phone) {
      setMessage(
        language.labels.authentication.username_phone_success.replace(
          /{phone}/g,
          phone
        )
      );
    } else {
      setMessage(
        language.labels.authentication.username_mail_success.replace(
          /{email}/g,
          email
        )
      );
    }

    if (animation && animation === 2) {
      document.getElementById("success").style.animationName = "slidein";
    }
  }, []);

  return (
    <div className="signup" id="success">
      <div
        role="status"
        style={{
          fontWeight: "500",
          fontSize: "1.2em",
          paddingBottom: "1em",
          textAlign: "center",
        }}
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />

      {email && (
        <div
          style={{
            marginTop: "1.5em",
            fontSize: "0.9em",
            fontWeight: 300,
            textAlign: "center",
          }}
        >
          {language.labels.authentication.email_advice}
        </div>
      )}
    </div>
  );
};

export default SuccessPage;
