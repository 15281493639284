import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TopBar, SideBar } from "../layout";
import { getContext } from "../../lib/signin";
import { PubBar, RemoveAccount } from "../widgets";

const AccountDelete = ({ language, signout, pageRefs, pageFunctions }) => {
  ///////////////// INITIALIZE /////////////////
  const auth = useSelector((state) => state.authentication.value);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
    } catch (err) {
      console.log(err);
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    }
  }, []);

  ///////////////// RENDER GUI /////////////////
  return (
    <>
      {auth.account_type ? (
        <div className="main" ref={pageRefs._main_}>
          <div
            className="sr-only"
            id="primary_focus"
            tabIndex={0}
            onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
          ></div>
          <div
            ref={pageRefs.skip_link}
            className="link sr-only"
            role="link"
            onClick={() => pageFunctions.skipLink(false)}
            onKeyUpCapture={(e) =>
              e.key === "Enter" && pageRefs.main_focus.current.focus()
            }
            onFocus={() => pageFunctions.skipLink()}
            onBlur={() => pageFunctions.skipLink(false)}
            tabIndex={0}
          >
            {language.labels.aria.skip}
          </div>
          <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
          <SideBar
            language={language}
            signout={signout}
            auth={auth}
            communities={communities.length}
            community={community}
            resetMenu={pageFunctions.resetMenu}
            ref={pageRefs.sidebar_ref}
          />
          <RemoveAccount
            language={language}
            auth={auth}
            signout={signout}
            main_focus={pageRefs.main_focus}
          />
        </div>
      ) : (
        <>
          <PubBar language={language} tabIndex={0} />
          <RemoveAccount
            language={language}
            auth={auth}
            signout={signout}
            main_focus={pageRefs.main_focus}
          />
        </>
      )}
    </>
  );
};

export default AccountDelete;
