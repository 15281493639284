const duration = (seconds) => {
  const sec = Math.floor(seconds);
  const min = Math.floor(seconds / 60);
  const hr = Math.floor(seconds / 3600);
  const s = sec - 60 * min;
  let m = min.toString() + ":";
  let h = hr.toString() + ":";

  if (sec < 10) {
    return "00:0" + sec.toString();
  } else if (sec < 60) {
    return "00:" + sec.toString();
  } else if (sec < 3600) {
    if (min < 10) m = "0" + min.toString() + ":";
    if (s < 10) {
      return m + "0" + s;
    } else {
      return m + s;
    }
  } else {
    let minutes = min - 60 * hr;
    if (minutes < 10) {
      m = "0" + minutes.toString() + ":";
    } else {
      m = minutes.toString() + ":";
    }
    if (s < 10) {
      return h + m + "0" + s.toString();
    } else {
      return h + m + s.toString();
    }
  }
};

const naturalDate = (date, locale, labels) => {
  const _date = new Date(date);
  const seconds = Math.round((Date.now() - _date) / 1000);
  const mins = Math.round(seconds / 60);
  const hrs = Math.round(seconds / 3600);

  if (seconds < 2) {
    return labels.now;
  } else if (seconds < 59) {
    return labels.seconds.replace(/{n}/g, seconds);
  } else if (seconds < 91) {
    return labels.minute;
  } else if (seconds < 3480) {
    return labels.minutes.replace(/{n}/g, mins);
  } else if (seconds < 5430) {
    return labels.hour;
  } else if (seconds < 43230) {
    return labels.hours.replace(/{n}/g, hrs);
  } else if (seconds < 86460) {
    return (
      _date.toLocaleDateString(locale, {
        day: "numeric",
        month: "short",
        year: "numeric",
      }) +
      " " +
      _date.toLocaleTimeString(locale, { hour: "numeric", minute: "2-digit" })
    );
  } else {
    return _date.toLocaleDateString(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }
};

const preciseDate = (date, locale) => {
  const _date = new Date(date);
  return (
    _date.toLocaleDateString(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
    }) +
    " " +
    _date.toLocaleTimeString(locale, { hour: "numeric", minute: "2-digit" })
  );
};

const recentDate = (ts, unit = "day", period) => {
  let _then = new Date(ts);
  let _when = new Date();
  if (unit === "month") {
    _when.setMonth(_when.getMonth() - period);
  } else {
    _when.setDate(_when.getDate() - period);
  }
  return _then > _when;
};

const trueTime = (date, locale) => {
  const _date = new Date(date);
  return _date.toLocaleTimeString(locale, {
    hour: "numeric",
    minute: "2-digit",
  });
};

export { duration, naturalDate, preciseDate, recentDate, trueTime };
