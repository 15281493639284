import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";
import { VideoEditor } from "../widgets/";
import axiosCall from "../../lib/axios";
import config from "../../config";
import { naturalDate } from "../../lib/date";
import Modal from "../modal/Modal";

const Video = ({ language, signout, pageRefs, pageFunctions }) => {
  //////////// INITIALIZE ///////////
  const auth = useSelector((state) => state.authentication.value);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const [actions, setActions] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(state?.report?.status);
  const [status, setStatus] = useState("");
  const [currentVideo, setCurrentVideo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      checkVideo();
    } catch (err) {
      console.log(err);
      signout();
    }
  };

  useEffect(() => {
    if (state?.params) {
      if (
        auth.agree_terms !== "x" &&
        auth.uid &&
        localStorage.getItem("communities")
      ) {
        let obj = JSON.parse(localStorage.getItem("communities"));
        initialize(obj, auth.community_id, auth.role);
      } else {
        signout();
      }
    } else {
      navigate("/videos");
    }
  }, []);

  useEffect(() => {
    switch (currentStatus) {
      case "accepted":
        setActions(["restore"]);
        break;
      case "pending":
        setActions(["reject", "remove"]);
        break;
      case "rejected":
        setActions(["pending"]);
        break;
      default:
        setActions(["pending", "reject", "remove"]);
        break;
    }
  }, [currentStatus]);

  const checkVideo = async () => {
    if (state.params && state.params._id) {
      let data = { vid: state.params._id };

      if (state.flagged) data["reporter"] = state.report.reporter;

      let result = await axiosCall("video/check", data);
      if (result.success) {
        if (state.flagged) {
          setCurrentVideo({ ...result.data });
        } else {
          let videoObj = { ...state.params };
          videoObj.bite_image = result.data.thumb;
          if (result.data.description) {
            // add or change description
            setCurrentVideo({
              ...videoObj,
              description: result.data.description,
            });
          } else {
            // remove description
            delete videoObj["description"];
            setCurrentVideo(videoObj);
          }
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        checkVideo();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } else {
      navigate("/videos");
    }
  };

  ////////////// FUNCTIONS //////////////////

  const closeVideo = () => {
    navigate("/videos");
  };

  const changeVideoDesc = (desc) => {
    setCurrentVideo({ ...currentVideo, description: desc });
  };

  const navBite = () => {
    navigate("/bite", {
      state: { bid: currentVideo.block, video: currentVideo },
    });
  };

  const takeAction = (action) => {
    if (action === "remove") {
      //do a popup that warns "are you sure etc..."
      //have an action on the modal so that if the admin cancels,
      setStatus("remove");
      setShowModal(true);
    } else {
      //change the status, update the page. stay on page
      setStatus(action);
      if (action === "reject" || action === "restore") {
        setCurrentStatus("rejected");
      } else {
        setCurrentStatus("pending");
      }
      updateStatus(action);
      setStatus("");
    }
  };

  const canModal = () => {
    setShowModal(false);
    setStatus("");
  };

  const OKModal = () => {
    setShowModal(false);
    setStatus("");
    setCurrentStatus("accepted");
    deleteVideo();
    //call api to delete video update the status and return to parent page
  };

  const updateStatus = async (selectedStatus) => {
    let data = {
      vid: state.params._id,
      action: selectedStatus,
    };

    let result = await axiosCall("report/settle/video", data);
    if (result.success) {
      navigate(".", {
        state: {
          params: { _id: state.params._id },
          flagged: true,
          report: { ...state.report, status: result.data },
        },
      });
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } else if (result.refresh) {
      //token has been refreshed, try again
      updateStatus(selectedStatus);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const deleteVideo = async () => {
    let result = await axiosCall("video/delete", {
      vid: state.params._id,
    });
    if (result.success) {
      setStatus("");
      navigate("/flagged");
    } else if (result.refresh) {
      //token has been refreshed, try again
      deleteVideo();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// RENDER GUI //////////////////
  const mapped_actions = actions.map((action, index) => {
    return (
      <option value={action} key={index} selected={status === action}>
        {language.labels.flags.actions[action]}
      </option>
    );
  });

  if (currentVideo._id) {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          {/* heading */}
          <div className="page-section">
            <h1 className="heading">{language.labels.videos.video}</h1>
          </div>
          {state.flagged && (
            <div className="page-section">
              <div
                style={{
                  display: "flex",
                  marginTop: "1em",
                  alignItems: "center",
                  gap: "1em",
                }}
              >
                <h2
                  className="heading2"
                  style={{ fontSize: "1.1em", fontWeight: "600" }}
                >
                  {language.labels.flags.id.replace(/{id}/g, state.report._id)}
                </h2>
              </div>

              <div>{state.report.category}</div>

              <div className="quote-text" style={{ marginTop: "0.5em" }}>
                <div>{state.report.description}</div>
              </div>

              {/* poster */}
              <div className="heading2" style={{ marginTop: "1em" }}>
                {language.labels.flags.reported_by}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  marginTop: "0.5em",
                  maxWidth: "288px",
                }}
              ></div>

              <div
                className="avatar med"
                style={{
                  flexShrink: "0",
                  backgroundImage: `url(${
                    config.server.storage.bucket + currentVideo.reporter.avatar
                  })`,
                }}
              ></div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="name" style={{ fontSize: "1em" }}>
                  {currentVideo.reporter.username}
                </div>

                <div style={{ fontSize: "0.7em", marginTop: "2px" }}>
                  {naturalDate(
                    state.report.created,
                    language.locale,
                    language.labels.date
                  )}
                </div>
              </div>

              <div style={{ marginTop: "1em", display: "flex", gap: "0.5em" }}>
                <div>{language.labels.app.status}</div>
                <div style={{ fontWeight: "500" }}>
                  {language.labels.flags.status[currentStatus]}
                </div>
              </div>

              {success && (
                <div
                  style={{ fontWeight: 600, marginTop: "1em" }}
                  className="success"
                  role="alert"
                >
                  {language.labels.flags.success}
                </div>
              )}

              {/* OPTIONS */}
              <div style={{ marginTop: "1em" }}>
                <select
                  className="large"
                  aria-label={language.labels.roles.role_choose}
                  value={status}
                  onChange={(e) => {
                    takeAction(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    {language.labels.flags.take_action}
                  </option>
                  {mapped_actions}
                </select>
              </div>

              <div style={{ marginTop: "2.5em" }}>
                <div className="line"></div>
              </div>
            </div>
          )}

          {/* block */}
          <div className="page-section">
            <h2 className="heading2" style={{ marginBottom: "0.25em" }}>
              {language.labels.videos.bite}
            </h2>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={currentVideo.bite_name}
                className="menu-crest"
                style={{
                  width: "3em",
                  height: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + currentVideo.bite_image
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <div
                  role="link"
                  className="link clamped one"
                  style={{
                    fontWeight: 500,
                    fontSize: "1.2em",
                    margin: "0.25em 0",
                  }}
                  onClick={navBite}
                  onKeyUpCapture={(e) => e.key === "Enter" && navBite()}
                  tabIndex={0}
                >
                  {currentVideo.bite_name}
                </div>
                <div className="handle2" style={{ width: "100%" }}>
                  {community.title}
                </div>
              </div>
            </div>
          </div>

          {/* video */}
          <div className="page-section">
            <VideoEditor
              style={{ maxWidth: "44rem", paddingBottom: "2em" }}
              language={language}
              community={community}
              currentVideo={currentVideo}
              flagged={state.flagged}
              onDelete={closeVideo}
              onModify={changeVideoDesc}
              onClose={closeVideo}
              navigate={navigate}
              signout={signout}
            ></VideoEditor>
          </div>

          <Modal
            className="modal-dynamic"
            title={language.labels.flags.warning}
            show={showModal}
          >
            {/* <div>{modalMessage}</div> */}
            <div style={{ display: "flex", gap: "0.25em", marginTop: "2em" }}>
              <button className="button-off" onClick={() => canModal()}>
                {language.labels.app.cancel}
              </button>
              <button className="button" onClick={() => OKModal()}>
                {language.labels.app.yes_sure}
              </button>
            </div>
          </Modal>
        </main>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Video;
